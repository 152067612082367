const week2 = [
  {
    tags: ['MALE'],
    sessionId: '5QrEsXnwQI',
    gameTime: '2025-01-11T18:00:00.000Z',
    title: 'Michigan / Greenville / Simpson',
    teamA: 'Michigan',
    teamALogo: 'upload/images/team/OlF6zJbX8C_3TY7v_vL5S',
    teamB: 'Greenville',
    teamBLogo: 'upload/images/team/ENm2b6qi96JA3DRXNyvt9',
    teamC: 'Simpson',
    teamCLogo: 'upload/images/team/5iJiCZuUmn0V7FPYx6-Oh',
  },
];

const week3 = [
  {
    tags: ['MALE'],
    sessionId: 'O3MPbAk0bz',
    gameTime: '2025-01-14T02:00:00.000Z',
    title: 'California vs Stanford',
    teamA: 'California',
    teamALogo: 'upload/images/team/cFqM7q75rvGOTzliayNOC',
    teamB: 'Stanford',
    teamBLogo: 'upload/images/team/TxEa6Awt5quagSXQIhqtk',
  },
];

const week4 = [
  {
    sessionId: 'yZd3FxBQCm',
    gameTime: '2025-01-25T00:00:00.000Z',
    title: 'Simpson vs Navy',
    tags: ['MALE', 'STREAMING'],
    teamA: 'Simpson',
    teamALogo: 'upload/images/team/5iJiCZuUmn0V7FPYx6-Oh',
    teamB: 'Navy',
    teamBLogo: 'upload/images/team/QtMgsTKjUwl0sEQwnlwNT',
  },
  {
    sessionId: 'a6Q8hnmwMz',
    gameTime: '2025-01-25T19:00:00.000Z',
    title: 'Springfield / Army / Michigan',
    tags: ['MALE', 'STREAMING'],
    teamA: 'Springfield',
    teamALogo: 'upload/images/team/uGniv3T8oFPiagZqgWXwv',
    teamB: 'Army',
    teamBLogo: 'upload/images/team/wmICCck438BshXvBlPHV_',
    teamC: 'Michigan',
    teamCLogo: 'upload/images/team/OlF6zJbX8C_3TY7v_vL5S',
  },
  {
    sessionId: 'zbgG-nrEiC',
    gameTime: '2025-01-26T03:00:00.000Z',
    title: 'Stanford / Cal / Ohio State (Stanford Open)',
    tags: ['MALE', 'STREAMING'],
    teamA: 'Stanford',
    teamALogo: 'upload/images/team/TxEa6Awt5quagSXQIhqtk',
    teamB: 'California',
    teamBLogo: 'upload/images/team/jfBUCDAAOrc9urkAN65iZ',
    teamC: 'Ohio State',
    teamCLogo: 'upload/images/team/1zvZAutg_lGASowMXuhnY',
  },
];

const week5 = [
  {
    sessionId: 'QGps_nLZTa',
    gameTime: '2025-02-01T18:00:00.000Z',
    title: 'Army vs Navy',
    tags: ['MALE', 'STREAMING'],
    teamA: 'Army',
    teamALogo: 'upload/images/team/wmICCck438BshXvBlPHV_',
    teamB: 'Navy',
    teamBLogo: 'upload/images/team/QtMgsTKjUwl0sEQwnlwNT',
  },
  {
    sessionId: 'wZNp4b3nWi',
    gameTime: '2025-02-01T19:00:00.000Z',
    title: 'Greenville vs Springfield',
    tags: ['MALE', 'STREAMING'],
    teamA: 'Greenville',
    teamALogo: 'upload/images/team/ENm2b6qi96JA3DRXNyvt9',
    teamB: 'Springfield',
    teamBLogo: 'upload/images/team/uGniv3T8oFPiagZqgWXwv',
  },
  {
    sessionId: 'InHzjn8SJ9',
    gameTime: '2025-02-02T21:00:00.000Z',
    title: 'Stanford vs California',
    tags: ['MALE', 'STREAMING'],
    teamA: 'Stanford',
    teamALogo: 'upload/images/team/TxEa6Awt5quagSXQIhqtk',
    teamB: 'California',
    teamBLogo: 'upload/images/team/jfBUCDAAOrc9urkAN65iZ',
  },
];

const week6 = [
  {
    sessionId: 'M4S0ftTvMQ',
    gameTime: '2025-02-08T21:00:00.000Z',
    title: 'Air Force vs California',
    tags: ['MALE'],
    teamA: 'Air Force',
    teamALogo: 'upload/images/team/AAz9Ikazg9k-YNOWvNnLa',
    teamB: 'California',
    teamBLogo: 'upload/images/team/jfBUCDAAOrc9urkAN65iZ',
  },
  {
    sessionId: '-6PrZjHzEA',
    gameTime: '2025-02-08T18:00:00.000Z',
    title: 'Army vs William & Mary',
    tags: ['MALE', 'STREAMING'],
    teamA: 'Army',
    teamALogo: 'upload/images/team/wmICCck438BshXvBlPHV_',
    teamB: 'William & Mary',
    teamBLogo: 'upload/images/team/MG4yA_-sLEqBckLyfy71-',
  },
  {
    sessionId: '4cPYSi41ti',
    gameTime: '2025-02-08T19:00:00.000Z',
    title: 'Michigan vs Illinois',
    tags: ['MALE'],
    teamA: 'Michigan',
    teamALogo: 'upload/images/team/OlF6zJbX8C_3TY7v_vL5S',
    teamB: 'Illinois',
    teamBLogo: 'upload/images/team/t9wWLsOPF2bpVZHqjsogl',
  },
];

const week7 = [
  {
    sessionId: 'ytGs9IqwF2',
    gameTime: '2025-02-16T18:00:00.000Z',
    title: 'Springfield vs William & Mary',
    tags: ['MALE', 'STREAMING'],
    teamA: 'Springfield',
    teamALogo: 'upload/images/team/uGniv3T8oFPiagZqgWXwv',
    teamB: 'William & Mary',
    teamBLogo: 'upload/images/team/MG4yA_-sLEqBckLyfy71-',
  },
];

export const matches2025 = [week7, week6, week5, week4, week3, week2];

// export function useMatch(key) {
//   const { data } = useQuery(GetMatch, {
//     variables: {
//       sessionKey: key,
//     },
//   });

//   return data;
// }
