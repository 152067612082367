import { useQuery } from '@apollo/client';
import GetMatch from '../apollo/queries/GetMatch.graphql';

const matchA = {
  sessionId: 'fdgLX4E1SM',
  gameTime: '2025-01-04T00:00:00.000Z',
  title: 'Mizzou / Illinois State / Ball State / SEMO',
  teamA: 'Mizzou',
  teamALogo: 'upload/images/team/hSA2_OvgGcAwhVnnZDHjL',
  teamD: 'SEMO',
  teamDLogo: 'upload/images/team/P9x9kIRocdJPCwq2sHg53',
  teamC: 'Ball State',
  teamCLogo: 'upload/images/team/S7efA9rBwUCOUQONAATb6',
  teamB: 'Illinois State',
  teamBLogo: 'upload/images/team/aw4N42hwRE2Ewc7dZ6prh',
};

const matchB = {
  sessionId: 'MpeU462GRX',
  gameTime: '2025-01-04T00:30:00.000Z',
  title: 'LSU vs Iowa State',
  teamA: 'LSU',
  teamALogo: 'upload/images/team/k3g_c_3ZBsgwZ1KIup38W',
  teamB: 'Iowa State',
  teamBLogo: 'upload/images/team/9__Q1pXevKzpCxsjpRkoA',
};

const matchC = {
  sessionId: '-S-9GXFaU9',
  gameTime: '2025-01-04T02:00:00.000Z',
  title: 'Utah vs Utah State',
  teamA: 'Utah',
  teamALogo: 'upload/images/team/4lpZr33sN1C_mxiYQk0LN',
  teamB: 'Utah State',
  teamBLogo: 'upload/images/team/HdErQDTTzua7xSgglBuEV',
};

const matchD = {
  sessionId: 'Duz3xEhfjD',
  gameTime: '2025-01-04T20:00:00.000Z',
  title: 'Temple vs WVU',
  teamA: 'Temple',
  teamALogo: 'upload/images/team/-2aUC7ugrc_ZH7Gcip46v',
  teamB: 'WVU',
  teamBLogo: 'upload/images/team/A7o7XcB3BbAxavXLRUBhF',
};

const matchE = {
  sessionId: '0LClTahVuX',
  gameTime: '2025-01-04T21:00:00.000Z',
  title: 'Michigan State vs WMU',
  teamA: 'Michigan State',
  teamALogo: 'upload/images/team/3fMqUSVleD8cXKx7acX-5',
  teamB: 'WMU',
  teamBLogo: 'upload/images/team/4njSVBoc7CyvU415YzKj0',
};

const matchF = {
  sessionId: 'dptP_r-G2B',
  gameTime: '2025-01-04T21:00:00.000Z',
  title: 'NC State vs Towson',
  teamA: 'NC State',
  teamALogo: 'upload/images/team/q3-Uqn-P1Eoa_XULt81_Z',
  teamB: 'Towson',
  teamBLogo: 'upload/images/team/7E-jHUkEK-WMl5uA31it-',
};

const matchG = {
  sessionId: 'WA2XXpcL9Y',
  gameTime: '2025-01-04T22:00:00.000Z',
  title: 'Minnesota vs CMU',
  teamA: 'Minnesota',
  teamALogo: 'upload/images/team/L1nfNwj-98ysElWuNme9W',
  teamB: 'CMU',
  teamBLogo: 'upload/images/team/TayK4o3QhC1gcKrHiPeX9',
};

const matchH = {
  sessionId: '44myF79mlq',
  gameTime: '2025-01-05T00:00:00.000Z',
  title: 'Gustavus vs UW-Eau Claire',
  teamA: 'Gustavus',
  teamALogo: 'upload/images/team/jueyFEPEII5hbEk_pHILg',
  teamB: 'UW-Eau Claire',
  teamBLogo: 'upload/images/team/LauSa_ZJaGoO2xcVMSdzx',
};

const matchI = {
  sessionId: 'Nx8_HJy76I',
  gameTime: '2025-01-05T22:00:00.000Z',
  title: 'Denver vs Michigan',
  teamA: 'Denver',
  teamALogo: 'upload/images/team/H04xwPRzTS5vbEFaXbx4W',
  teamB: 'Michigan',
  teamBLogo: 'upload/images/team/_pStX-aYWTvumVvf_BPYe',
};

const matchPCC1 = {
  sessionId: 'd6RrNMAAXg',
  gameTime: '2025-01-04T20:30:00.000Z',
  title: 'American Gold Oceanside Session #1',
  teamA: 'California',
  teamALogo: 'upload/images/team/AhdgNN4VK4EiOT8YMJavo',
  teamB: 'UCLA',
  teamBLogo: 'upload/images/team/LvA1VLX0gEgeqNb11WmGm',
  teamC: 'Oregon State',
  teamCLogo: 'upload/images/team/SUgsb-OU3HGO7oWFRzfhd',
};

const matchPCC2 = {
  sessionId: 'KDl2-fLv83',
  gameTime: '2025-01-05T02:30:00.000Z',
  title: 'American Gold Oceanside Session #2',
  teamA: 'Southern Utah',
  teamALogo: 'upload/images/team/O_CTTamlZYZlPw-le0CkD',
  teamB: 'Wilberforce',
  teamBLogo: 'upload/images/team/zOJ1Nd2gMndq1UlNH-PT4',
  teamC: 'Rutgers',
  teamCLogo: 'upload/images/team/Keb4zpuxg0jy2H45dR0dD',
  teamD: 'Arizona',
  teamDLogo: 'upload/images/team/IvFg7MbvgCuET9yVr2j2-',
};

const matchJ = {
  sessionId: 'PvhRcNHPGs',
  gameTime: '2025-01-10T23:45:00.000Z',
  title: 'Florida / NIU / Nebraska / Michigan State',
  teamA: 'Florida',
  teamALogo: 'upload/images/team/2EuQerjmGSQ3QnPZENaQl',
  teamB: 'NIU',
  teamBLogo: 'upload/images/team/F0jgfsNlRXL8b3Qq54Cb-',
  teamC: 'Nebraska',
  teamCLogo: 'upload/images/team/T3KXi0DD27Z8-2uXk4MNV',
  teamD: 'Michigan State',
  teamDLogo: 'upload/images/team/3fMqUSVleD8cXKx7acX-5',
};

const matchK = {
  sessionId: 'Ov5vRFjXP4',
  gameTime: '2025-01-11T00:00:00.000Z',
  title: 'UW-Oshkosh vs Gustavus',
  teamA: 'UW-Oshkosh',
  teamALogo: 'upload/images/team/Gq5i5lMGRFcxbXrFBVTp9',
  teamB: 'Gustavus',
  teamBLogo: 'upload/images/team/jueyFEPEII5hbEk_pHILg',
};

const matchL = {
  sessionId: 'ARJRpQxqVK',
  gameTime: '2025-01-11T00:00:00.000Z',
  title: 'UW-Stout vs UW-Whitewater',
  teamA: 'UW-Stout',
  teamALogo: 'upload/images/team/2uKGYOQeEjD5U6ofcavh2',
  teamB: 'UW-Whitewater',
  teamBLogo: 'upload/images/team/CbWKimoC_0RpBy-M-lcSy',
};

const matchM = {
  sessionId: 'F_AZunfGBr',
  gameTime: '2025-01-11T00:30:00.000Z',
  title: 'Iowa State / Brown / Iowa',
  teamA: 'Iowa State',
  teamALogo: 'upload/images/team/9__Q1pXevKzpCxsjpRkoA',
  teamB: 'Brown',
  teamBLogo: 'upload/images/team/bm282RMpGww0qoStmL9Gr',
  teamC: 'Iowa',
  teamCLogo: 'upload/images/team/p8ENUoErkkuHz3msOYb8d',
};

const matchN = {
  sessionId: 'wskbMlQk7d',
  gameTime: '2025-01-11T01:00:00.000Z',
  title: 'Alabama vs UNC',
  teamA: 'Alabama',
  teamALogo: 'upload/images/team/5krk0_YwXvq_C58VbmYD6',
  teamB: 'UNC',
  teamBLogo: 'upload/images/team/2zuCt_Fvldy7Y7Nh4yCuG',
};

const matchO = {
  sessionId: 'ZfOY8XT1r6',
  gameTime: '2025-01-11T02:00:00.000Z',
  title: 'Air Force vs LIU',
  teamA: 'Air Force',
  teamALogo: 'upload/images/team/y1PAeO8-bQUAsnjjxlYqx',
  teamB: 'LIU',
  teamBLogo: 'upload/images/team/_aJBZM4RBuKXJMTdfBOvC',
};

const matchSprouts1 = {
  sessionId: 'vWnQNu9hy1',
  gameTime: '2025-01-11T02:00:00.000Z',
  title: 'Sprouts: Session 1',
  teamA: 'Michigan',
  teamALogo: 'upload/images/team/_pStX-aYWTvumVvf_BPYe',
  teamB: 'NC State',
  teamBLogo: 'upload/images/team/q3-Uqn-P1Eoa_XULt81_Z',
  teamC: 'Clemson',
  teamCLogo: 'upload/images/team/M_ZZbKT6N5hoeI9gOzW08',
  teamD: 'Arizona',
  teamDLogo: 'upload/images/team/IvFg7MbvgCuET9yVr2j2-',
};

const matchP = {
  sessionId: 'shQiEjiM7X',
  gameTime: '2025-01-11T02:30:00.000Z',
  title: 'Washington / Boise State / Stanford / UC Davis',
  teamA: 'Washington',
  teamALogo: 'upload/images/team/PxHRrEmCh4h8dFmITMnSM',
  teamB: 'Boise State',
  teamBLogo: 'upload/images/team/y_1ONBk8XQMNlCXqUolhg',
  teamC: 'Stanford',
  teamCLogo: 'upload/images/team/0W6sAzrt4E1huvv82J16X',
  teamD: 'UC Davis',
  teamDLogo: 'upload/images/team/FSkO6kJa0O9yd7mc4kyck',
};

const matchR = {
  sessionId: 'GfTuQI4PkX',
  gameTime: '2025-01-11T18:00:00.000Z',
  title: 'Rutgers / Towson / West Chester / Penn',
  teamA: 'Rutgers',
  teamALogo: 'upload/images/team/Keb4zpuxg0jy2H45dR0dD',
  teamB: 'Towson',
  teamBLogo: 'upload/images/team/7E-jHUkEK-WMl5uA31it-',
  teamC: 'West Chester',
  teamCLogo: 'upload/images/team/GMA9gX5dEfSAXJ_3RuGJe',
  teamD: 'Penn',
  teamDLogo: 'upload/images/team/7H7GYktvhz4ygAfhHs-Ed',
};

const matchSprouts2 = {
  sessionId: 'HEhOhRGmU0',
  gameTime: '2025-01-11T21:00:00.000Z',
  title: 'Sprouts: Session 2',
  teamA: 'LSU',
  teamALogo: 'upload/images/team/k3g_c_3ZBsgwZ1KIup38W',
  teamB: 'California',
  teamBLogo: 'upload/images/team/AhdgNN4VK4EiOT8YMJavo',
  teamC: 'Oklahoma',
  teamCLogo: 'upload/images/team/JjSGKxJh81xZlOMu-Oxd1',
  teamD: 'Utah',
  teamDLogo: 'upload/images/team/4lpZr33sN1C_mxiYQk0LN',
};

const matchT = {
  tags: ['STREAMING'],
  sessionId: '30o6--RuCC',
  gameTime: '2025-01-11T19:00:00.000Z',
  title: 'Lone Star Collegiate Invitational',
  teamA: 'Penn State',
  teamALogo: 'upload/images/team/RfZGXFyiH2JuGXjQ007hQ',
  teamB: 'Arizona State',
  teamBLogo: 'upload/images/team/ZaoNOG0LZOU9gwoMdGrnz',
  teamC: 'Auburn',
  teamCLogo: 'upload/images/team/5WPFADXjkdDjfNTUnv9b2',
  teamD: 'Temple',
  teamDLogo: 'upload/images/team/-2aUC7ugrc_ZH7Gcip46v',
  teamE: 'Maryland',
  teamELogo: 'upload/images/team/b3SmbX-UKRPk1HkzRPrD_',
};

const matchU = {
  sessionId: 'NkT98iicEg',
  gameTime: '2025-01-11T23:00:00.000Z',
  title: 'UNH vs Minnesota',
  teamA: 'UNH',
  teamALogo: 'upload/images/team/Ad1PKfzx7Ayt-g_rVjLaX',
  teamB: 'Minnesota',
  teamBLogo: 'upload/images/team/L1nfNwj-98ysElWuNme9W',
};

const matchSprouts3 = {
  sessionId: 'GMdHlGf2Fx',
  gameTime: '2025-01-12T01:00:00.000Z',
  title: 'Sprouts: Session 3',
  teamA: 'Arkansas',
  teamALogo: 'upload/images/team/Y4pMJrwhkkku_HEtQvYKN',
  teamB: 'Kentucky',
  teamBLogo: 'upload/images/team/jBxu8sY8t1WJSF6e43fOh',
  teamC: 'UCLA',
  teamCLogo: 'upload/images/team/LvA1VLX0gEgeqNb11WmGm',
  teamD: 'Ohio State',
  teamDLogo: 'upload/images/team/aK78bVxajwW7haLwXIGaM',
};

const matchV = {
  sessionId: 'xlSz9w1mN7',
  gameTime: '2025-01-11T22:00:00.000Z',
  title: 'Oregon State vs BYU',
  teamA: 'Oregon State',
  teamALogo: 'upload/images/team/SUgsb-OU3HGO7oWFRzfhd',
  teamB: 'BYU',
  teamBLogo: 'upload/images/team/IjOElnrwW4y1C_fOjQDqP',
};

const matchW = {
  sessionId: 'gOvT_1rMcP',
  gameTime: '2025-01-12T17:00:00.000Z',
  title: 'Cornell / Yale / Bridgeport',
  teamA: 'Cornell',
  teamALogo: 'upload/images/team/9uULeRpRgc-bXv0239H3a',
  teamB: 'Yale',
  teamBLogo: 'upload/images/team/-l6HqzpxgzJauEm2zPaNJ',
  teamC: 'Bridgeport',
  teamCLogo: 'upload/images/team/4nMkHZZ0t_RtwvQEFqEMr',
};

const matchX = {
  sessionId: 'rpMT-46Aqt',
  gameTime: '2025-01-12T18:00:00.000Z',
  title: 'Ball State / Illinois / SEMO / UW-Eau Claire',
  teamA: 'Ball State',
  teamALogo: 'upload/images/team/S7efA9rBwUCOUQONAATb6',
  teamB: 'Illinois',
  teamBLogo: 'upload/images/team/9f9Eo7XB1tk4pQ0zNIudV',
  teamC: 'SEMO',
  teamCLogo: 'upload/images/team/P9x9kIRocdJPCwq2sHg53',
  teamD: 'UW-Eau Claire',
  teamDLogo: 'upload/images/team/LauSa_ZJaGoO2xcVMSdzx',
};

const matchY = {
  sessionId: 'gb_WjlwpSa',
  gameTime: '2025-01-12T18:00:00.000Z',
  title: 'CMU vs WVU',
  teamA: 'CMU',
  teamALogo: 'upload/images/team/TayK4o3QhC1gcKrHiPeX9',
  teamB: 'WVU',
  teamBLogo: 'upload/images/team/A7o7XcB3BbAxavXLRUBhF',
};

const matchZ = {
  sessionId: 'PlrpJumwtf',
  gameTime: '2025-01-12T18:00:00.000Z',
  title: 'Cortland / Ursinus / Utica / RIC / Springfield',
  teamA: 'Cortland',
  teamALogo: 'upload/images/team/aQuR5cQdzlNHGsY89kU1F',
  teamB: 'Ursinus',
  teamBLogo: 'upload/images/team/X0jwCzEEfHa25usxd9wpD',
  teamC: 'Utica',
  teamCLogo: 'upload/images/team/kZ-c-F3GqjCA8fNwlZxKp',
  teamD: 'RIC',
  teamDLogo: 'upload/images/team/UVeeOAqNplREWtucLClb-',
  teamE: 'Springfield',
  teamELogo: 'upload/images/team/B90OmKJn62sRjh404Y3eD',
};

const matchAA = {
  sessionId: 'xkkFoovsc0',
  gameTime: '2025-01-12T18:00:00.000Z',
  title: 'Eastern Michigan vs Bowling Green',
  teamA: 'Eastern Michigan',
  teamALogo: 'upload/images/team/6cWyxe7aJmnRMuhatRDwQ',
  teamB: 'Bowling Green',
  teamBLogo: 'upload/images/team/2zMuDgazSTePXdNLXu6cG',
};

const matchAB = {
  sessionId: 'IomwxQokQh',
  gameTime: '2025-01-12T19:00:00.000Z',
  title: 'Hamline vs UWL',
  teamA: 'Hamline',
  teamALogo: 'upload/images/team/lJNBrvLeQMzRkmVaGvf5e',
  teamB: 'UWL',
  teamBLogo: 'upload/images/team/3m9gkYeibmj5CT99Ay7Ey',
};

const matchAC = {
  sessionId: 'XTEEhUx31z',
  gameTime: '2025-01-12T20:00:00.000Z',
  title: 'TWU vs Centenary',
  teamA: 'TWU',
  teamALogo: 'upload/images/team/RlQ4ghHJPe45JOh4yT6Uy',
  teamB: 'Centenary',
  teamBLogo: 'upload/images/team/qf-eFY2ramiGGd-35gIe3',
};

const matchAD = {
  sessionId: 'E6NxvsUZbg',
  gameTime: '2025-01-12T20:30:00.000Z',
  title: 'Ithaca vs Brockport',
  teamA: 'Ithaca',
  teamALogo: 'upload/images/team/QA39D-hmXma8IzFp4m__b',
  teamB: 'Brockport',
  teamBLogo: 'upload/images/team/ghW_zO5ZLUPbYGniXSAR-',
};

const matchAE = {
  sessionId: '7st8eQ8Zcy',
  gameTime: '2025-01-12T21:00:00.000Z',
  title: 'Denver / Mizzou / Georgia / LIU',
  teamA: 'Denver',
  teamALogo: 'upload/images/team/H04xwPRzTS5vbEFaXbx4W',
  teamB: 'Mizzou',
  teamBLogo: 'upload/images/team/hSA2_OvgGcAwhVnnZDHjL',
  teamC: 'Georgia',
  teamCLogo: 'upload/images/team/4meFHEcSQmI9SgiY7CIwr',
  teamD: 'LIU',
  teamDLogo: 'upload/images/team/_aJBZM4RBuKXJMTdfBOvC',
};

const matchS = {
  tags: ['STREAMING'],
  sessionId: '0RghuECB_n',
  gameTime: '2025-01-11T20:00:00.000Z',
  title: 'Simpson / Winona State / Greenville',
  teamA: 'Simpson',
  teamALogo: 'upload/images/team/e4ReYbsesszxOxrprmRfI',
  teamB: 'Winona State',
  teamBLogo: 'upload/images/team/WeJiXDNMqwN034-bzJsev',
  teamC: 'Greenville',
  teamCLogo: 'upload/images/team/DvpQ8aJqMsi2aXyHkqWpb',
};

const matchAF = {
  sessionId: 'ApXiQ5121L',
  gameTime: '2025-01-17T23:00:00.000Z',
  title: 'EMU / BSU / Winona State / NIU',
  teamA: 'Eastern Michigan',
  teamALogo: 'upload/images/team/6cWyxe7aJmnRMuhatRDwQ',
  teamB: 'Ball State',
  teamBLogo: 'upload/images/team/S7efA9rBwUCOUQONAATb6',
  teamC: 'Winona State',
  teamCLogo: 'upload/images/team/WeJiXDNMqwN034-bzJsev',
  teamD: 'NIU',
  teamDLogo: 'upload/images/team/F0jgfsNlRXL8b3Qq54Cb-',
};

const matchAG = {
  sessionId: 'KNZKcwqOIA',
  gameTime: '2025-01-17T23:00:00.000Z',
  title: 'Michigan State vs Michigan',
  teamA: 'Michigan State',
  teamALogo: 'upload/images/team/3fMqUSVleD8cXKx7acX-5',
  teamB: 'Michigan',
  teamBLogo: 'upload/images/team/_pStX-aYWTvumVvf_BPYe',
};

const matchAH = {
  sessionId: 'XZBTFDIE4w',
  gameTime: '2025-01-17T23:00:00.000Z',
  title: 'Penn / Towson / Yale / Ursinus',
  teamA: 'Penn',
  teamALogo: 'upload/images/team/7H7GYktvhz4ygAfhHs-Ed',
  teamB: 'Towson',
  teamBLogo: 'upload/images/team/7E-jHUkEK-WMl5uA31it-',
  teamC: 'Yale',
  teamCLogo: 'upload/images/team/-l6HqzpxgzJauEm2zPaNJ',
  teamD: 'Ursinus',
  teamDLogo: 'upload/images/team/X0jwCzEEfHa25usxd9wpD',
};

const matchAI = {
  sessionId: 'gxzBe9JPsZ',
  gameTime: '2025-01-18T00:00:00.000Z',
  title: 'Auburn vs Arkansas',
  teamA: 'Auburn',
  teamALogo: 'upload/images/team/5WPFADXjkdDjfNTUnv9b2',
  teamB: 'Arkansas',
  teamBLogo: 'upload/images/team/Y4pMJrwhkkku_HEtQvYKN',
};

const matchAJ = {
  sessionId: 'iTn2fGWTFe',
  gameTime: '2025-01-18T00:00:00.000Z',
  title: 'CMU vs Bowling Green',
  teamA: 'CMU',
  teamALogo: 'upload/images/team/TayK4o3QhC1gcKrHiPeX9',
  teamB: 'Bowling Green',
  teamBLogo: 'upload/images/team/2zMuDgazSTePXdNLXu6cG',
};

const matchAK = {
  sessionId: 'VO4WT0sfIj',
  gameTime: '2025-01-18T00:00:00.000Z',
  title: 'Georgia vs Boise State',
  teamA: 'Georgia',
  teamALogo: 'upload/images/team/4meFHEcSQmI9SgiY7CIwr',
  teamB: 'Boise State',
  teamBLogo: 'upload/images/team/y_1ONBk8XQMNlCXqUolhg',
};

const matchAL = {
  sessionId: 'cVO_ErB3iD',
  gameTime: '2025-01-18T00:00:00.000Z',
  title: 'Kentucky vs Alabama',
  teamA: 'Kentucky',
  teamALogo: 'upload/images/team/jBxu8sY8t1WJSF6e43fOh',
  teamB: 'Alabama',
  teamBLogo: 'upload/images/team/5krk0_YwXvq_C58VbmYD6',
};

const matchAM = {
  sessionId: 'KW6ZfvAhUp',
  gameTime: '2025-01-18T00:00:00.000Z',
  title: 'Pitt vs Clemson',
  teamA: 'Pitt',
  teamALogo: 'upload/images/team/3dd_oTAMydg9FcSTb_14p',
  teamB: 'Clemson',
  teamBLogo: 'upload/images/team/M_ZZbKT6N5hoeI9gOzW08',
};

const matchAN = {
  sessionId: '5v9mOkmDxz',
  gameTime: '2025-01-18T00:00:00.000Z',
  title: 'UNC vs GW',
  teamA: 'UNC',
  teamALogo: 'upload/images/team/2zuCt_Fvldy7Y7Nh4yCuG',
  teamB: 'GW',
  teamBLogo: 'upload/images/team/R7CeYsmiyWvBNZKrG88UH',
};

const matchAO = {
  sessionId: 'CWqN8_YroK',
  gameTime: '2025-01-18T00:00:00.000Z',
  title: 'UW-Eau Claire vs UWL',
  teamA: 'UW-Eau Claire',
  teamALogo: 'upload/images/team/LauSa_ZJaGoO2xcVMSdzx',
  teamB: 'UWL',
  teamBLogo: 'upload/images/team/3m9gkYeibmj5CT99Ay7Ey',
};

const matchAP = {
  sessionId: 'Uli8XPtdRH',
  gameTime: '2025-01-18T00:30:00.000Z',
  title: 'Hamline vs UW-Oshkosh',
  teamA: 'Hamline',
  teamALogo: 'upload/images/team/lJNBrvLeQMzRkmVaGvf5e',
  teamB: 'UW-Oshkosh',
  teamBLogo: 'upload/images/team/Gq5i5lMGRFcxbXrFBVTp9',
};

const matchAQ = {
  sessionId: 'ByTXMmG1Xm',
  gameTime: '2025-01-18T00:30:00.000Z',
  title: 'LSU vs Florida',
  teamA: 'LSU',
  teamALogo: 'upload/images/team/k3g_c_3ZBsgwZ1KIup38W',
  teamB: 'Florida',
  teamBLogo: 'upload/images/team/2EuQerjmGSQ3QnPZENaQl',
};

const matchAR = {
  sessionId: 'KaAv7vNrKG',
  gameTime: '2025-01-18T01:00:00.000Z',
  title: 'TWU / SEMO / West Chester',
  teamA: 'TWU',
  teamALogo: 'upload/images/team/RlQ4ghHJPe45JOh4yT6Uy',
  teamB: 'SEMO',
  teamBLogo: 'upload/images/team/P9x9kIRocdJPCwq2sHg53',
  teamC: 'West Chester',
  teamCLogo: 'upload/images/team/GMA9gX5dEfSAXJ_3RuGJe',
};

const matchAS = {
  sessionId: 'onlRXz3E53',
  gameTime: '2025-01-18T02:00:00.000Z',
  title: 'Arizona vs Air Force',
  teamA: 'Arizona',
  teamALogo: 'upload/images/team/IvFg7MbvgCuET9yVr2j2-',
  teamB: 'Air Force',
  teamBLogo: 'upload/images/team/y1PAeO8-bQUAsnjjxlYqx',
};

const matchAT = {
  sessionId: 'qaUCXdvebV',
  gameTime: '2025-01-18T02:00:00.000Z',
  title: 'BYU vs Southern Utah',
  teamA: 'BYU',
  teamALogo: 'upload/images/team/IjOElnrwW4y1C_fOjQDqP',
  teamB: 'Southern Utah',
  teamBLogo: 'upload/images/team/O_CTTamlZYZlPw-le0CkD',
};

const matchAU = {
  sessionId: 'xOvei8Lrx1',
  gameTime: '2025-01-18T02:00:00.000Z',
  title: 'Utah vs Iowa State',
  teamA: 'Utah',
  teamALogo: 'upload/images/team/4lpZr33sN1C_mxiYQk0LN',
  teamB: 'Iowa State',
  teamBLogo: 'upload/images/team/9__Q1pXevKzpCxsjpRkoA',
};

const matchAV = {
  sessionId: 'Iu-XBV4mTz',
  gameTime: '2025-01-18T03:00:00.000Z',
  title: 'Oregon State vs San Jose State',
  teamA: 'Oregon State',
  teamALogo: 'upload/images/team/SUgsb-OU3HGO7oWFRzfhd',
  teamB: 'San Jose State',
  teamBLogo: 'upload/images/team/2OpYQV8pvTrxham5gmSdg',
};

const matchAW = {
  sessionId: 'oY1DYVK3Wl',
  gameTime: '2025-01-18T18:00:00.000Z',
  title: 'SCSU / Bridgeport / Brown / Springfield',
  teamA: 'SCSU',
  teamALogo: 'upload/images/team/fdZErpFakdfjKY8u7q-kS',
  teamB: 'Bridgeport',
  teamBLogo: 'upload/images/team/4nMkHZZ0t_RtwvQEFqEMr',
  teamC: 'Brown',
  teamCLogo: 'upload/images/team/bm282RMpGww0qoStmL9Gr',
  teamD: 'Springfield',
  teamDLogo: 'upload/images/team/B90OmKJn62sRjh404Y3eD',
};

const matchAX = {
  sessionId: 'wHjrzrGLU2',
  gameTime: '2025-01-18T19:00:00.000Z',
  title: 'Brockport vs Cortland',
  teamA: 'Brockport',
  teamALogo: 'upload/images/team/ghW_zO5ZLUPbYGniXSAR-',
  teamB: 'Cortland',
  teamBLogo: 'upload/images/team/aQuR5cQdzlNHGsY89kU1F',
};

const matchAY = {
  sessionId: 'lHnKB39ovr',
  gameTime: '2025-01-18T19:00:00.000Z',
  title: 'Rutgers vs Washington',
  teamA: 'Rutgers',
  teamALogo: 'upload/images/team/Keb4zpuxg0jy2H45dR0dD',
  teamB: 'Washington',
  teamBLogo: 'upload/images/team/PxHRrEmCh4h8dFmITMnSM',
};

const matchAZ = {
  sessionId: 'Yr580Q5TFq',
  gameTime: '2025-01-18T21:00:00.000Z',
  title: 'RIC / Utica / Gustavus',
  teamA: 'RIC',
  teamALogo: 'upload/images/team/UVeeOAqNplREWtucLClb-',
  teamB: 'Utica',
  teamBLogo: 'upload/images/team/kZ-c-F3GqjCA8fNwlZxKp',
  teamC: 'Gustavus',
  teamCLogo: 'upload/images/team/jueyFEPEII5hbEk_pHILg',
};

const matchAAA = {
  sessionId: 'aEQOpNTW2I',
  gameTime: '2025-01-18T22:00:00.000Z',
  title: 'Minnesota vs Penn State',
  teamA: 'Minnesota',
  teamALogo: 'upload/images/team/L1nfNwj-98ysElWuNme9W',
  teamB: 'Penn State',
  teamBLogo: 'upload/images/team/RfZGXFyiH2JuGXjQ007hQ',
};

const matchAAB = {
  sessionId: 'FxaN298QQK',
  gameTime: '2025-01-18T22:00:00.000Z',
  title: 'Stanford vs NC State',
  teamA: 'Stanford',
  teamALogo: 'upload/images/team/0W6sAzrt4E1huvv82J16X',
  teamB: 'NC State',
  teamBLogo: 'upload/images/team/q3-Uqn-P1Eoa_XULt81_Z',
};

const matchAAC = {
  sessionId: 'dPI-K1I0id',
  gameTime: '2025-01-18T22:00:00.000Z',
  title: 'UW-Whitewater vs Simpson',
  teamA: 'UW-Whitewater',
  teamALogo: 'upload/images/team/CbWKimoC_0RpBy-M-lcSy',
  teamB: 'Simpson',
  teamBLogo: 'upload/images/team/e4ReYbsesszxOxrprmRfI',
};

const matchAAD = {
  sessionId: 'wFd7qINV7q',
  gameTime: '2025-01-19T00:30:00.000Z',
  title: 'Maryland vs UCLA',
  teamA: 'Maryland',
  teamALogo: 'upload/images/team/b3SmbX-UKRPk1HkzRPrD_',
  teamB: 'UCLA',
  teamBLogo: 'upload/images/team/LvA1VLX0gEgeqNb11WmGm',
};

// const matchAAE = {
//   sessionId: 'mGiM1yZy8x',
//   gameTime: '2025-01-19T18:00:00.000Z',
//   title: 'Ursinus vs Ithaca',
//   teamA: 'Ursinus',
//   teamALogo: 'upload/images/team/X0jwCzEEfHa25usxd9wpD',
//   teamB: 'Ithaca',
//   teamBLogo: 'upload/images/team/QA39D-hmXma8IzFp4m__b',
// };

const matchAAF = {
  sessionId: 'A6w8awQ_af',
  gameTime: '2025-01-20T19:00:00.000Z',
  title: 'Centenary / SEMO / West Chester',
  teamA: 'Centenary',
  teamALogo: 'upload/images/team/qf-eFY2ramiGGd-35gIe3',
  teamB: 'SEMO',
  teamBLogo: 'upload/images/team/P9x9kIRocdJPCwq2sHg53',
  teamC: 'West Chester',
  teamCLogo: 'upload/images/team/GMA9gX5dEfSAXJ_3RuGJe',
};

const matchAAG = {
  sessionId: 'vYeaGwlt8r',
  gameTime: '2025-01-20T20:00:00.000Z',
  title: 'Arkansas / TWU / Denver',
  teamA: 'Arkansas',
  teamALogo: 'upload/images/team/Y4pMJrwhkkku_HEtQvYKN',
  teamB: 'TWU',
  teamBLogo: 'upload/images/team/RlQ4ghHJPe45JOh4yT6Uy',
  teamC: 'Denver',
  teamCLogo: 'upload/images/team/H04xwPRzTS5vbEFaXbx4W',
};

const matchAAH = {
  sessionId: '3xh5Ztn816',
  gameTime: '2025-01-20T20:00:00.000Z',
  title: 'Wasatch Classic',
  teamA: 'Arizona State',
  teamALogo: 'upload/images/team/ZaoNOG0LZOU9gwoMdGrnz',
  teamB: 'Iowa State',
  teamBLogo: 'upload/images/team/9__Q1pXevKzpCxsjpRkoA',
  teamC: 'Boise State',
  teamCLogo: 'upload/images/team/y_1ONBk8XQMNlCXqUolhg',
  teamD: 'San Jose State',
  teamDLogo: 'upload/images/team/2OpYQV8pvTrxham5gmSdg',
};

const matchAAI = {
  sessionId: '51_yMYPLUm',
  gameTime: '2025-01-21T01:00:00.000Z',
  title: 'Best of Utah',
  teamA: 'Southern Utah',
  teamALogo: 'upload/images/team/O_CTTamlZYZlPw-le0CkD',
  teamB: 'Utah',
  teamBLogo: 'upload/images/team/4lpZr33sN1C_mxiYQk0LN',
  teamC: 'BYU',
  teamCLogo: 'upload/images/team/IjOElnrwW4y1C_fOjQDqP',
  teamD: 'Utah State',
  teamDLogo: 'upload/images/team/HdErQDTTzua7xSgglBuEV',
};

const week4 = [
  {
    sessionId: '6eU9B37vht',
    gameTime: '2025-01-25T02:00:00.000Z',
    title: 'Air Force vs Utah State',
    tags: [''],
    teamA: 'Air Force',
    teamALogo: 'upload/images/team/y1PAeO8-bQUAsnjjxlYqx',
    teamB: 'Utah State',
    teamBLogo: 'upload/images/team/HdErQDTTzua7xSgglBuEV',
  },
  {
    sessionId: 'Qd9N4dArTI',
    gameTime: '2025-01-25T01:00:00.000Z',
    title: 'Alabama vs Oklahoma',
    tags: [''],
    teamA: 'Alabama',
    teamALogo: 'upload/images/team/5krk0_YwXvq_C58VbmYD6',
    teamB: 'Oklahoma',
    teamBLogo: 'upload/images/team/JjSGKxJh81xZlOMu-Oxd1',
  },
  {
    sessionId: 'u7TTnEAEwP',
    gameTime: '2025-01-26T22:00:00.000Z',
    title: 'Arizona State vs Arizona',
    tags: [''],
    teamA: 'Arizona State',
    teamALogo: 'upload/images/team/ZaoNOG0LZOU9gwoMdGrnz',
    teamB: 'Arizona',
    teamBLogo: 'upload/images/team/IvFg7MbvgCuET9yVr2j2-',
  },
  {
    sessionId: 'csmJ1gwlEC',
    gameTime: '2025-01-25T00:45:00.000Z',
    title: 'Arkansas vs LSU',
    tags: [''],
    teamA: 'Arkansas',
    teamALogo: 'upload/images/team/Y4pMJrwhkkku_HEtQvYKN',
    teamB: 'LSU',
    teamBLogo: 'upload/images/team/k3g_c_3ZBsgwZ1KIup38W',
  },
  {
    sessionId: 'tO_GqSgGof',
    gameTime: '2025-01-25T02:30:00.000Z',
    title: 'Auburn vs Oregon State',
    tags: [''],
    teamA: 'Auburn',
    teamALogo: 'upload/images/team/5WPFADXjkdDjfNTUnv9b2',
    teamB: 'Oregon State',
    teamBLogo: 'upload/images/team/SUgsb-OU3HGO7oWFRzfhd',
  },
  {
    sessionId: 'yiNxq0BnvP',
    gameTime: '2025-01-26T18:00:00.000Z',
    title: 'Ball State / WMU / Wilberforce',
    tags: [''],
    teamA: 'Ball State',
    teamALogo: 'upload/images/team/S7efA9rBwUCOUQONAATb6',
    teamB: 'WMU',
    teamBLogo: 'upload/images/team/4njSVBoc7CyvU415YzKj0',
    teamC: 'Wilberforce',
    teamCLogo: 'upload/images/team/zOJ1Nd2gMndq1UlNH-PT4',
  },
  {
    sessionId: 'DF3q7aEhRT',
    gameTime: '2025-01-26T19:00:00.000Z',
    title: 'Bowling Green / SEMO / CMU',
    tags: [''],
    teamA: 'Bowling Green',
    teamALogo: 'upload/images/team/2zMuDgazSTePXdNLXu6cG',
    teamB: 'SEMO',
    teamBLogo: 'upload/images/team/P9x9kIRocdJPCwq2sHg53',
    teamC: 'CMU',
    teamCLogo: 'upload/images/team/TayK4o3QhC1gcKrHiPeX9',
  },
  {
    sessionId: 'St9HqgY2vY',
    gameTime: '2025-01-25T22:00:00.000Z',
    title: 'Bridgeport / Cornell / West Chester / Centenary',
    tags: ['STREAMING'],
    teamA: 'Bridgeport',
    teamALogo: 'upload/images/team/4nMkHZZ0t_RtwvQEFqEMr',
    teamB: 'Cornell',
    teamBLogo: 'upload/images/team/9uULeRpRgc-bXv0239H3a',
    teamC: 'West Chester',
    teamCLogo: 'upload/images/team/GMA9gX5dEfSAXJ_3RuGJe',
    teamD: 'Centenary',
    teamDLogo: 'upload/images/team/qf-eFY2ramiGGd-35gIe3',
  },
  {
    sessionId: 'aEau7Y1DBS',
    gameTime: '2025-01-26T19:00:00.000Z',
    title: 'Brockport vs RIC',
    tags: [''],
    teamA: 'Brockport',
    teamALogo: 'upload/images/team/ghW_zO5ZLUPbYGniXSAR-',
    teamB: 'RIC',
    teamBLogo: 'upload/images/team/UVeeOAqNplREWtucLClb-',
  },
  {
    sessionId: 'fLxud0de1S',
    gameTime: '2025-01-26T17:00:00.000Z',
    title: 'Brown / SCSU / Springfield',
    tags: [''],
    teamA: 'Brown',
    teamALogo: 'upload/images/team/bm282RMpGww0qoStmL9Gr',
    teamB: 'SCSU',
    teamBLogo: 'upload/images/team/fdZErpFakdfjKY8u7q-kS',
    teamC: 'Springfield',
    teamCLogo: 'upload/images/team/B90OmKJn62sRjh404Y3eD',
  },
  {
    sessionId: 'OsYHKIIILn',
    gameTime: '2025-01-25T02:00:00.000Z',
    title: 'BYU vs Utah',
    tags: [''],
    teamA: 'BYU',
    teamALogo: 'upload/images/team/IjOElnrwW4y1C_fOjQDqP',
    teamB: 'Utah',
    teamBLogo: 'upload/images/team/4lpZr33sN1C_mxiYQk0LN',
  },
  {
    sessionId: '7fN1d19mLG',
    gameTime: '2025-01-26T22:00:00.000Z',
    title: 'California vs Clemson',
    tags: [''],
    teamA: 'California',
    teamALogo: 'upload/images/team/AhdgNN4VK4EiOT8YMJavo',
    teamB: 'Clemson',
    teamBLogo: 'upload/images/team/M_ZZbKT6N5hoeI9gOzW08',
  },
  {
    sessionId: 'WnwMpJ1ps7',
    gameTime: '2025-01-26T20:45:00.000Z',
    title: 'Denver vs WVU',
    tags: [''],
    teamA: 'Denver',
    teamALogo: 'upload/images/team/H04xwPRzTS5vbEFaXbx4W',
    teamB: 'WVU',
    teamBLogo: 'upload/images/team/A7o7XcB3BbAxavXLRUBhF',
  },
  {
    sessionId: '_Jywdw8pWV',
    gameTime: '2025-01-26T18:00:00.000Z',
    title: 'Eastern Michigan vs Kent State',
    tags: [''],
    teamA: 'Eastern Michigan',
    teamALogo: 'upload/images/team/6cWyxe7aJmnRMuhatRDwQ',
    teamB: 'Kent State',
    teamBLogo: 'upload/images/team/_MlyfrlwP0n50yFezrUXy',
  },
  {
    sessionId: 's89RLSahgh',
    gameTime: '2025-01-24T23:15:00.000Z',
    title: 'Florida vs Georgia',
    tags: [''],
    teamA: 'Florida',
    teamALogo: 'upload/images/team/2EuQerjmGSQ3QnPZENaQl',
    teamB: 'Georgia',
    teamBLogo: 'upload/images/team/4meFHEcSQmI9SgiY7CIwr',
  },
  {
    sessionId: 'S__J4QgHHE',
    gameTime: '2025-01-26T18:00:00.000Z',
    title: 'GW / Yale / Penn / Fisk (Lindsey Ferris Invite)',
    tags: [''],
    teamA: 'GW',
    teamALogo: 'upload/images/team/R7CeYsmiyWvBNZKrG88UH',
    teamB: 'Yale',
    teamBLogo: 'upload/images/team/-l6HqzpxgzJauEm2zPaNJ',
    teamC: 'Penn',
    teamCLogo: 'upload/images/team/7H7GYktvhz4ygAfhHs-Ed',
    teamD: 'Fisk',
    teamDLogo: 'upload/images/team/OfBVOo4OcfTz9HyW5LcJz',
  },
  {
    sessionId: 'U1XecoHGDl',
    gameTime: '2025-01-25T00:30:00.000Z',
    title: 'Hamline vs UW-Stout',
    tags: [''],
    teamA: 'Hamline',
    teamALogo: 'upload/images/team/lJNBrvLeQMzRkmVaGvf5e',
    teamB: 'UW-Stout',
    teamBLogo: 'upload/images/team/2uKGYOQeEjD5U6ofcavh2',
  },
  {
    sessionId: 'qx5WtiLTlO',
    gameTime: '2025-01-25T01:00:00.000Z',
    title: 'Iowa State vs Simpson',
    tags: [''],
    teamA: 'Iowa State',
    teamALogo: 'upload/images/team/9__Q1pXevKzpCxsjpRkoA',
    teamB: 'Simpson',
    teamBLogo: 'upload/images/team/e4ReYbsesszxOxrprmRfI',
  },
  {
    sessionId: 'CVdZZuN4SF',
    gameTime: '2025-01-26T23:00:00.000Z',
    title: 'Mizzou vs Kentucky',
    tags: [''],
    teamA: 'Mizzou',
    teamALogo: 'upload/images/team/hSA2_OvgGcAwhVnnZDHjL',
    teamB: 'Kentucky',
    teamBLogo: 'upload/images/team/jBxu8sY8t1WJSF6e43fOh',
  },
  {
    sessionId: 'v4xObbuWWQ',
    gameTime: '2025-01-25T00:00:00.000Z',
    title: 'NIU vs Illinois State',
    tags: [''],
    teamA: 'NIU',
    teamALogo: 'upload/images/team/F0jgfsNlRXL8b3Qq54Cb-',
    teamB: 'Illinois State',
    teamBLogo: 'upload/images/team/aw4N42hwRE2Ewc7dZ6prh',
  },
  {
    sessionId: 'VEfYuVMzno',
    gameTime: '2025-01-26T21:00:00.000Z',
    title: 'Pitt vs Stanford',
    tags: [''],
    teamA: 'Pitt',
    teamALogo: 'upload/images/team/3dd_oTAMydg9FcSTb_14p',
    teamB: 'Stanford',
    teamBLogo: 'upload/images/team/0W6sAzrt4E1huvv82J16X',
  },
  {
    sessionId: 'ybNpdSzJdc',
    gameTime: '2025-01-26T22:00:00.000Z',
    title: 'San Jose State / Boise State / Sac State / UC Davis',
    tags: [''],
    teamA: 'San Jose State',
    teamALogo: 'upload/images/team/2OpYQV8pvTrxham5gmSdg',
    teamB: 'Boise State',
    teamBLogo: 'upload/images/team/y_1ONBk8XQMNlCXqUolhg',
    teamC: 'Sac State',
    teamCLogo: 'upload/images/team/o6Lk9LwIRcHlaeJ_0z1ZJ',
    teamD: 'UC Davis',
    teamDLogo: 'upload/images/team/FSkO6kJa0O9yd7mc4kyck',
  },
  {
    sessionId: 'o4jVQRQGId',
    gameTime: '2025-01-25T22:00:00.000Z',
    title: 'UCLA vs Illinois',
    tags: [''],
    teamA: 'UCLA',
    teamALogo: 'upload/images/team/LvA1VLX0gEgeqNb11WmGm',
    teamB: 'Illinois',
    teamBLogo: 'upload/images/team/9f9Eo7XB1tk4pQ0zNIudV',
  },
  {
    sessionId: 'iYuWGBEYd-',
    gameTime: '2025-01-25T00:00:00.000Z',
    title: 'UNC vs NC State',
    tags: [''],
    teamA: 'UNC',
    teamALogo: 'upload/images/team/2zuCt_Fvldy7Y7Nh4yCuG',
    teamB: 'NC State',
    teamBLogo: 'upload/images/team/q3-Uqn-P1Eoa_XULt81_Z',
  },
  {
    sessionId: 'vrIk1Q4Yw7',
    gameTime: '2025-01-26T19:00:00.000Z',
    title: 'UNH vs LIU',
    tags: [''],
    teamA: 'UNH',
    teamALogo: 'upload/images/team/Ad1PKfzx7Ayt-g_rVjLaX',
    teamB: 'LIU',
    teamBLogo: 'upload/images/team/_aJBZM4RBuKXJMTdfBOvC',
  },
  {
    sessionId: 'EBiwcgK8jd',
    gameTime: '2025-01-25T00:00:00.000Z',
    title: 'UW-Oshkosh vs UW-Eau Claire',
    tags: [''],
    teamA: 'UW-Oshkosh',
    teamALogo: 'upload/images/team/Gq5i5lMGRFcxbXrFBVTp9',
    teamB: 'UW-Eau Claire',
    teamBLogo: 'upload/images/team/LauSa_ZJaGoO2xcVMSdzx',
  },
  {
    sessionId: 'PsiyNJOhN9',
    gameTime: '2025-01-26T04:00:00.000Z',
    title: 'Washington vs Michigan',
    tags: [''],
    teamA: 'Washington',
    teamALogo: 'upload/images/team/PxHRrEmCh4h8dFmITMnSM',
    teamB: 'Michigan',
    teamBLogo: 'upload/images/team/_pStX-aYWTvumVvf_BPYe',
  },
  {
    sessionId: 'c1AM6AlegO',
    gameTime: '2025-01-25T00:00:00.000Z',
    title: 'Winona State / Gustavus / UWL',
    tags: [''],
    teamA: 'Winona State',
    teamALogo: 'upload/images/team/WeJiXDNMqwN034-bzJsev',
    teamB: 'Gustavus',
    teamBLogo: 'upload/images/team/jueyFEPEII5hbEk_pHILg',
    teamC: 'UWL',
    teamCLogo: 'upload/images/team/3m9gkYeibmj5CT99Ay7Ey',
  },
];

const week5 = [
  {
    sessionId: 'LfmaOHXhIg',
    gameTime: '2025-01-29T00:00:00.000Z',
    title: 'Illinois State vs NIU',
    tags: ['FEMALE'],
    teamA: 'Illinois State',
    teamALogo: 'upload/images/team/aw4N42hwRE2Ewc7dZ6prh',
    teamB: 'NIU',
    teamBLogo: 'upload/images/team/F0jgfsNlRXL8b3Qq54Cb-',
  },
  {
    sessionId: 'pI2xy4tIh3',
    gameTime: '2025-01-27T22:00:00.000Z',
    title: 'William & Mary vs Temple',
    tags: ['FEMALE', 'STREAMING'],
    teamA: 'William & Mary',
    teamALogo: 'upload/images/team/I7JzeUKc_Vorq8GCRtXXT',
    teamB: 'Temple',
    teamBLogo: 'upload/images/team/-2aUC7ugrc_ZH7Gcip46v',
  },
  {
    sessionId: 'fOizstpIU6',
    gameTime: '2025-02-01T02:00:00.000Z',
    title: 'Air Force / San Jose State / Hamline',
    tags: ['FEMALE'],
    teamA: 'Air Force',
    teamALogo: 'upload/images/team/y1PAeO8-bQUAsnjjxlYqx',
    teamB: 'San Jose State',
    teamBLogo: 'upload/images/team/2OpYQV8pvTrxham5gmSdg',
    teamC: 'Hamline',
    teamCLogo: 'upload/images/team/lJNBrvLeQMzRkmVaGvf5e',
  },
  {
    sessionId: 'h7Aw3ppEr1',
    gameTime: '2025-02-01T02:00:00.000Z',
    title: 'Arizona vs Denver',
    tags: ['FEMALE'],
    teamA: 'Arizona',
    teamALogo: 'upload/images/team/IvFg7MbvgCuET9yVr2j2-',
    teamB: 'Denver',
    teamBLogo: 'upload/images/team/H04xwPRzTS5vbEFaXbx4W',
  },
  {
    sessionId: '3hHk87xnmI',
    gameTime: '2025-02-01T02:00:00.000Z',
    title: 'Boise State / Utah State / Sac State',
    tags: ['FEMALE'],
    teamA: 'Boise State',
    teamALogo: 'upload/images/team/y_1ONBk8XQMNlCXqUolhg',
    teamB: 'Utah State',
    teamBLogo: 'upload/images/team/HdErQDTTzua7xSgglBuEV',
    teamC: 'Sac State',
    teamCLogo: 'upload/images/team/o6Lk9LwIRcHlaeJ_0z1ZJ',
  },
  {
    sessionId: 'jAYE8U_86R',
    gameTime: '2025-02-02T19:00:00.000Z',
    title: 'Bowling Green vs Ball State',
    tags: ['FEMALE'],
    teamA: 'Bowling Green',
    teamALogo: 'upload/images/team/2zMuDgazSTePXdNLXu6cG',
    teamB: 'Ball State',
    teamBLogo: 'upload/images/team/S7efA9rBwUCOUQONAATb6',
  },
  {
    sessionId: '1WJaSCM4IO',
    gameTime: '2025-02-01T02:00:00.000Z',
    title: 'BYU / UC Davis / Southern Utah',
    tags: ['FEMALE'],
    teamA: 'BYU',
    teamALogo: 'upload/images/team/IjOElnrwW4y1C_fOjQDqP',
    teamB: 'UC Davis',
    teamBLogo: 'upload/images/team/FSkO6kJa0O9yd7mc4kyck',
    teamC: 'Southern Utah',
    teamCLogo: 'upload/images/team/O_CTTamlZYZlPw-le0CkD',
  },
  {
    sessionId: 'Ov13RCthZX',
    gameTime: '2025-02-01T00:00:00.000Z',
    title: 'Centenary vs Greenville',
    tags: ['FEMALE'],
    teamA: 'Centenary',
    teamALogo: 'upload/images/team/qf-eFY2ramiGGd-35gIe3',
    teamB: 'Greenville',
    teamBLogo: 'upload/images/team/DvpQ8aJqMsi2aXyHkqWpb',
  },
  {
    sessionId: 'ZRI8YYg3xp',
    gameTime: '2025-02-01T00:00:00.000Z',
    title: 'Clemson vs Pitt',
    tags: ['FEMALE'],
    teamA: 'Clemson',
    teamALogo: 'upload/images/team/M_ZZbKT6N5hoeI9gOzW08',
    teamB: 'Pitt',
    teamBLogo: 'upload/images/team/3dd_oTAMydg9FcSTb_14p',
  },
  {
    sessionId: 'RHVciK_rbD',
    gameTime: '2025-02-02T18:00:00.000Z',
    title: 'Cornell vs Penn',
    tags: ['FEMALE'],
    teamA: 'Cornell',
    teamALogo: 'upload/images/team/9uULeRpRgc-bXv0239H3a',
    teamB: 'Penn',
    teamBLogo: 'upload/images/team/7H7GYktvhz4ygAfhHs-Ed',
  },
  {
    sessionId: 'oKrxDpzjNe',
    gameTime: '2025-02-01T00:00:00.000Z',
    title: 'Georgia vs Alabama',
    tags: ['FEMALE'],
    teamA: 'Georgia',
    teamALogo: 'upload/images/team/4meFHEcSQmI9SgiY7CIwr',
    teamB: 'Alabama',
    teamBLogo: 'upload/images/team/5krk0_YwXvq_C58VbmYD6',
  },
  {
    sessionId: 'TRk4fM4vls',
    gameTime: '2025-02-01T00:30:00.000Z',
    title: 'Iowa State vs Arizona State',
    tags: ['FEMALE'],
    teamA: 'Iowa State',
    teamALogo: 'upload/images/team/9__Q1pXevKzpCxsjpRkoA',
    teamB: 'Arizona State',
    teamBLogo: 'upload/images/team/ZaoNOG0LZOU9gwoMdGrnz',
  },
  {
    sessionId: 'YX7vaT7P6Z',
    gameTime: '2025-02-01T00:00:00.000Z',
    title: 'Kentucky vs Auburn',
    tags: ['FEMALE'],
    teamA: 'Kentucky',
    teamALogo: 'upload/images/team/jBxu8sY8t1WJSF6e43fOh',
    teamB: 'Auburn',
    teamBLogo: 'upload/images/team/5WPFADXjkdDjfNTUnv9b2',
  },
  {
    sessionId: 'fiZgwzlYrH',
    gameTime: '2025-02-01T01:30:00.000Z',
    title: 'LSU vs Mizzou',
    tags: ['FEMALE'],
    teamA: 'LSU',
    teamALogo: 'upload/images/team/k3g_c_3ZBsgwZ1KIup38W',
    teamB: 'Mizzou',
    teamBLogo: 'upload/images/team/hSA2_OvgGcAwhVnnZDHjL',
  },
  {
    sessionId: 'zBO2yDYTmN',
    gameTime: '2025-01-31T23:45:00.000Z',
    title: 'Michigan vs Ohio State',
    tags: ['FEMALE'],
    teamA: 'Michigan',
    teamALogo: 'upload/images/team/_pStX-aYWTvumVvf_BPYe',
    teamB: 'Ohio State',
    teamBLogo: 'upload/images/team/aK78bVxajwW7haLwXIGaM',
  },
  {
    sessionId: '9lISU0XT0V',
    gameTime: '2025-02-01T22:00:00.000Z',
    title: 'Minnesota vs Washington',
    tags: ['FEMALE'],
    teamA: 'Minnesota',
    teamALogo: 'upload/images/team/L1nfNwj-98ysElWuNme9W',
    teamB: 'Washington',
    teamBLogo: 'upload/images/team/PxHRrEmCh4h8dFmITMnSM',
  },
  {
    sessionId: '6Znpxxqq2J',
    gameTime: '2025-02-02T21:00:00.000Z',
    title: 'NC State vs California',
    tags: ['FEMALE'],
    teamA: 'NC State',
    teamALogo: 'upload/images/team/q3-Uqn-P1Eoa_XULt81_Z',
    teamB: 'California',
    teamBLogo: 'upload/images/team/AhdgNN4VK4EiOT8YMJavo',
  },
  {
    sessionId: 'mkI6w7aHBF',
    gameTime: '2025-02-02T19:00:00.000Z',
    title: 'NIU vs Eastern Michigan',
    tags: ['FEMALE'],
    teamA: 'NIU',
    teamALogo: 'upload/images/team/F0jgfsNlRXL8b3Qq54Cb-',
    teamB: 'Eastern Michigan',
    teamBLogo: 'upload/images/team/6cWyxe7aJmnRMuhatRDwQ',
  },
  {
    sessionId: '3HubPEV2-w',
    gameTime: '2025-02-02T18:00:00.000Z',
    title: 'Rutgers / Maryland / Fisk / Utica',
    tags: ['FEMALE'],
    teamA: 'Rutgers',
    teamALogo: 'upload/images/team/Keb4zpuxg0jy2H45dR0dD',
    teamB: 'Maryland',
    teamBLogo: 'upload/images/team/b3SmbX-UKRPk1HkzRPrD_',
    teamC: 'Fisk',
    teamCLogo: 'upload/images/team/OfBVOo4OcfTz9HyW5LcJz',
    teamD: 'Utica',
    teamDLogo: 'upload/images/team/kZ-c-F3GqjCA8fNwlZxKp',
  },
  {
    sessionId: '8VNCcsvx9w',
    gameTime: '2025-02-01T00:30:00.000Z',
    title: 'SEMO vs Illinois State',
    tags: ['FEMALE'],
    teamA: 'SEMO',
    teamALogo: 'upload/images/team/P9x9kIRocdJPCwq2sHg53',
    teamB: 'Illinois State',
    teamBLogo: 'upload/images/team/aw4N42hwRE2Ewc7dZ6prh',
  },
  {
    sessionId: '_Qk2sJmGy-',
    gameTime: '2025-02-01T19:00:00.000Z',
    title: 'Simpson vs UW-Oshkosh',
    tags: ['FEMALE', 'STREAMING'],
    teamA: 'Simpson',
    teamALogo: 'upload/images/team/e4ReYbsesszxOxrprmRfI',
    teamB: 'UW-Oshkosh',
    teamBLogo: 'upload/images/team/Gq5i5lMGRFcxbXrFBVTp9',
  },
  {
    sessionId: 'n8Xf33gLIn',
    gameTime: '2025-02-02T18:00:00.000Z',
    title: 'Springfield / Ithaca / RIC / Ursinus',
    tags: ['FEMALE', 'STREAMING'],
    teamA: 'Springfield',
    teamALogo: 'upload/images/team/B90OmKJn62sRjh404Y3eD',
    teamB: 'Ithaca',
    teamBLogo: 'upload/images/team/QA39D-hmXma8IzFp4m__b',
    teamC: 'RIC',
    teamCLogo: 'upload/images/team/UVeeOAqNplREWtucLClb-',
    teamD: 'Ursinus',
    teamDLogo: 'upload/images/team/X0jwCzEEfHa25usxd9wpD',
  },
  {
    sessionId: 'HfKG00-0ni',
    gameTime: '2025-02-01T03:00:00.000Z',
    title: 'Stanford vs UNC',
    tags: ['FEMALE'],
    teamA: 'Stanford',
    teamALogo: 'upload/images/team/0W6sAzrt4E1huvv82J16X',
    teamB: 'UNC',
    teamBLogo: 'upload/images/team/2zuCt_Fvldy7Y7Nh4yCuG',
  },
  {
    sessionId: 'C6yWcj0Z2c',
    gameTime: '2025-02-02T19:00:00.000Z',
    title: 'Temple / GW / Towson / LIU / UNH',
    tags: ['FEMALE'],
    teamA: 'Temple',
    teamALogo: 'upload/images/team/-2aUC7ugrc_ZH7Gcip46v',
    teamB: 'GW',
    teamBLogo: 'upload/images/team/R7CeYsmiyWvBNZKrG88UH',
    teamC: 'Towson',
    teamCLogo: 'upload/images/team/7E-jHUkEK-WMl5uA31it-',
    teamD: 'LIU',
    teamDLogo: 'upload/images/team/_aJBZM4RBuKXJMTdfBOvC',
    teamE: 'UNH',
    teamELogo: 'upload/images/team/Ad1PKfzx7Ayt-g_rVjLaX',
  },
  {
    sessionId: '6ndPN8hd0p',
    gameTime: '2025-02-02T20:00:00.000Z',
    title: 'TWU vs Air Force',
    tags: ['FEMALE'],
    teamA: 'TWU',
    teamALogo: 'upload/images/team/RlQ4ghHJPe45JOh4yT6Uy',
    teamB: 'Air Force',
    teamBLogo: 'upload/images/team/y1PAeO8-bQUAsnjjxlYqx',
  },
  {
    sessionId: 'kOUFOGkkZA',
    gameTime: '2025-02-01T22:30:00.000Z',
    title: 'UCLA vs Michigan State',
    tags: ['FEMALE'],
    teamA: 'UCLA',
    teamALogo: 'upload/images/team/LvA1VLX0gEgeqNb11WmGm',
    teamB: 'Michigan State',
    teamBLogo: 'upload/images/team/3fMqUSVleD8cXKx7acX-5',
  },
  {
    sessionId: '9Fu0ZJLAP8',
    gameTime: '2025-02-01T00:00:00.000Z',
    title: 'UW-Stout vs UW-Eau Claire',
    tags: ['FEMALE'],
    teamA: 'UW-Stout',
    teamALogo: 'upload/images/team/2uKGYOQeEjD5U6ofcavh2',
    teamB: 'UW-Eau Claire',
    teamBLogo: 'upload/images/team/LauSa_ZJaGoO2xcVMSdzx',
  },
  {
    sessionId: 'tBDH3bhm3n',
    gameTime: '2025-02-01T00:30:00.000Z',
    title: 'UWL vs Gustavus',
    tags: ['FEMALE'],
    teamA: 'UWL',
    teamALogo: 'upload/images/team/3m9gkYeibmj5CT99Ay7Ey',
    teamB: 'Gustavus',
    teamBLogo: 'upload/images/team/jueyFEPEII5hbEk_pHILg',
  },
  {
    sessionId: 'sbtxSDUmjB',
    gameTime: '2025-02-01T18:00:00.000Z',
    title: 'West Chester / Cortland / Brockport',
    tags: ['FEMALE', 'STREAMING'],
    teamA: 'West Chester',
    teamALogo: 'upload/images/team/GMA9gX5dEfSAXJ_3RuGJe',
    teamB: 'Cortland',
    teamBLogo: 'upload/images/team/aQuR5cQdzlNHGsY89kU1F',
    teamC: 'Brockport',
    teamCLogo: 'upload/images/team/ghW_zO5ZLUPbYGniXSAR-',
  },
  {
    sessionId: 'CE3fQgcrT2',
    gameTime: '2025-02-01T00:00:00.000Z',
    title: 'Winona State vs UW-Whitewater',
    tags: ['FEMALE'],
    teamA: 'Winona State',
    teamALogo: 'upload/images/team/WeJiXDNMqwN034-bzJsev',
    teamB: 'UW-Whitewater',
    teamBLogo: 'upload/images/team/CbWKimoC_0RpBy-M-lcSy',
  },
  {
    sessionId: '3xaASa8qmv',
    gameTime: '2025-02-02T18:00:00.000Z',
    title: 'Yale / SCSU / Bridgeport',
    tags: ['FEMALE'],
    teamA: 'Yale',
    teamALogo: 'upload/images/team/-l6HqzpxgzJauEm2zPaNJ',
    teamB: 'SCSU',
    teamBLogo: 'upload/images/team/fdZErpFakdfjKY8u7q-kS',
    teamC: 'Bridgeport',
    teamCLogo: 'upload/images/team/4nMkHZZ0t_RtwvQEFqEMr',
  },
];

const week6 = [
  {
    sessionId: 'VpPFZtdvmA',
    gameTime: '2025-02-09T20:00:00.000Z',
    title: 'Air Force / Boise State / Greenville',
    tags: ['FEMALE'],
    teamA: 'Air Force',
    teamALogo: 'upload/images/team/y1PAeO8-bQUAsnjjxlYqx',
    teamB: 'Boise State',
    teamBLogo: 'upload/images/team/y_1ONBk8XQMNlCXqUolhg',
    teamC: 'Greenville',
    teamCLogo: 'upload/images/team/DvpQ8aJqMsi2aXyHkqWpb',
  },
  {
    sessionId: 'WVTJFRW3gE',
    gameTime: '2025-02-08T02:00:00.000Z',
    title: 'Alabama vs LSU',
    tags: ['FEMALE'],
    teamA: 'Alabama',
    teamALogo: 'upload/images/team/5krk0_YwXvq_C58VbmYD6',
    teamB: 'LSU',
    teamBLogo: 'upload/images/team/k3g_c_3ZBsgwZ1KIup38W',
  },
  {
    sessionId: '6RqkNV6aSC',
    gameTime: '2025-02-08T01:30:00.000Z',
    title: 'Arizona State vs TWU',
    tags: ['FEMALE'],
    teamA: 'Arizona State',
    teamALogo: 'upload/images/team/ZaoNOG0LZOU9gwoMdGrnz',
    teamB: 'TWU',
    teamBLogo: 'upload/images/team/RlQ4ghHJPe45JOh4yT6Uy',
  },
  {
    sessionId: 'ZVp-Fp7xpN',
    gameTime: '2025-02-07T23:45:00.000Z',
    title: 'Arkansas vs Florida',
    tags: ['FEMALE'],
    teamA: 'Arkansas',
    teamALogo: 'upload/images/team/Y4pMJrwhkkku_HEtQvYKN',
    teamB: 'Florida',
    teamBLogo: 'upload/images/team/2EuQerjmGSQ3QnPZENaQl',
  },
  {
    sessionId: 'B7r6jrl5Hs',
    gameTime: '2025-02-08T01:00:00.000Z',
    title: 'Auburn vs Georgia',
    tags: ['FEMALE'],
    teamA: 'Auburn',
    teamALogo: 'upload/images/team/5WPFADXjkdDjfNTUnv9b2',
    teamB: 'Georgia',
    teamBLogo: 'upload/images/team/4meFHEcSQmI9SgiY7CIwr',
  },
  {
    sessionId: '85kL8L7bHV',
    gameTime: '2025-02-09T18:00:00.000Z',
    title: 'Ball State vs CMU',
    tags: ['FEMALE'],
    teamA: 'Ball State',
    teamALogo: 'upload/images/team/S7efA9rBwUCOUQONAATb6',
    teamB: 'CMU',
    teamBLogo: 'upload/images/team/TayK4o3QhC1gcKrHiPeX9',
  },
  {
    sessionId: 'VKbWUMeYuU',
    gameTime: '2025-02-09T19:30:00.000Z',
    title: 'Bridgeport / RIC / Springfield / Wilberforce',
    tags: ['FEMALE', 'STREAMING'],
    teamA: 'Bridgeport',
    teamALogo: 'upload/images/team/4nMkHZZ0t_RtwvQEFqEMr',
    teamB: 'RIC',
    teamBLogo: 'upload/images/team/UVeeOAqNplREWtucLClb-',
    teamC: 'Springfield',
    teamCLogo: 'upload/images/team/B90OmKJn62sRjh404Y3eD',
    teamD: 'Wilberforce',
    teamDLogo: 'upload/images/team/zOJ1Nd2gMndq1UlNH-PT4',
  },
  {
    sessionId: 'A3nf6mIvbb',
    gameTime: '2025-02-08T03:00:00.000Z',
    title: 'California vs Stanford',
    tags: ['FEMALE'],
    teamA: 'California',
    teamALogo: 'upload/images/team/AhdgNN4VK4EiOT8YMJavo',
    teamB: 'Stanford',
    teamBLogo: 'upload/images/team/0W6sAzrt4E1huvv82J16X',
  },
  {
    sessionId: 'pTkLJdnscR',
    gameTime: '2025-02-06T23:00:00.000Z',
    title: 'CMU / UW-Oshkosh / SEMO',
    tags: ['FEMALE'],
    teamA: 'CMU',
    teamALogo: 'upload/images/team/TayK4o3QhC1gcKrHiPeX9',
    teamB: 'UW-Oshkosh',
    teamBLogo: 'upload/images/team/Gq5i5lMGRFcxbXrFBVTp9',
    teamC: 'SEMO',
    teamCLogo: 'upload/images/team/P9x9kIRocdJPCwq2sHg53',
  },
  {
    sessionId: 'jDCl__RL3x',
    gameTime: '2025-02-09T18:00:00.000Z',
    title: 'Cornell / Brockport / Cortland / Ithaca / LIU / Utica',
    tags: ['FEMALE'],
    teamA: 'Cornell',
    teamALogo: 'upload/images/team/9uULeRpRgc-bXv0239H3a',
    teamB: 'Brockport',
    teamBLogo: 'upload/images/team/ghW_zO5ZLUPbYGniXSAR-',
    teamC: 'Cortland',
    teamCLogo: 'upload/images/team/aQuR5cQdzlNHGsY89kU1F',
    teamD: 'Ithaca',
    teamDLogo: 'upload/images/team/QA39D-hmXma8IzFp4m__b',
    teamE: 'LIU',
    teamELogo: 'upload/images/team/_aJBZM4RBuKXJMTdfBOvC',
    teamF: 'Utica',
    teamFLogo: 'upload/images/team/kZ-c-F3GqjCA8fNwlZxKp',
  },
  {
    sessionId: '3mRdTk6cpT',
    gameTime: '2025-02-09T01:45:00.000Z',
    title: 'Denver vs BYU',
    tags: ['FEMALE'],
    teamA: 'Denver',
    teamALogo: 'upload/images/team/H04xwPRzTS5vbEFaXbx4W',
    teamB: 'BYU',
    teamBLogo: 'upload/images/team/IjOElnrwW4y1C_fOjQDqP',
  },
  {
    sessionId: '5dXppQuD4y',
    gameTime: '2025-02-08T19:00:00.000Z',
    title: 'Gustavus vs UW-Whitewater',
    tags: ['FEMALE'],
    teamA: 'Gustavus',
    teamALogo: 'upload/images/team/jueyFEPEII5hbEk_pHILg',
    teamB: 'UW-Whitewater',
    teamBLogo: 'upload/images/team/CbWKimoC_0RpBy-M-lcSy',
  },
  {
    sessionId: 'EIVoMPS1LM',
    gameTime: '2025-02-07T23:30:00.000Z',
    title: 'Maryland vs Penn State',
    tags: ['FEMALE'],
    teamA: 'Maryland',
    teamALogo: 'upload/images/team/b3SmbX-UKRPk1HkzRPrD_',
    teamB: 'Penn State',
    teamBLogo: 'upload/images/team/RfZGXFyiH2JuGXjQ007hQ',
  },
  {
    sessionId: 'YCm7KDWhfH',
    gameTime: '2025-02-09T00:00:00.000Z',
    title: 'Michigan State vs Minnesota',
    tags: ['FEMALE'],
    teamA: 'Michigan State',
    teamALogo: 'upload/images/team/3fMqUSVleD8cXKx7acX-5',
    teamB: 'Minnesota',
    teamBLogo: 'upload/images/team/L1nfNwj-98ysElWuNme9W',
  },
  {
    sessionId: 'IA3gjX4-60',
    gameTime: '2025-02-09T17:45:00.000Z',
    title: 'Michigan vs Nebraska',
    tags: ['FEMALE'],
    teamA: 'Michigan',
    teamALogo: 'upload/images/team/_pStX-aYWTvumVvf_BPYe',
    teamB: 'Nebraska',
    teamBLogo: 'upload/images/team/T3KXi0DD27Z8-2uXk4MNV',
  },
  {
    sessionId: 'NbWAl7s44r',
    gameTime: '2025-02-08T21:00:00.000Z',
    title: 'NC State vs Pitt',
    tags: ['FEMALE'],
    teamA: 'NC State',
    teamALogo: 'upload/images/team/q3-Uqn-P1Eoa_XULt81_Z',
    teamB: 'Pitt',
    teamBLogo: 'upload/images/team/3dd_oTAMydg9FcSTb_14p',
  },
  {
    sessionId: 'RGKLMn2WhF',
    gameTime: '2025-02-08T01:00:00.000Z',
    title: 'NIU vs Kent State',
    tags: ['FEMALE'],
    teamA: 'NIU',
    teamALogo: 'upload/images/team/F0jgfsNlRXL8b3Qq54Cb-',
    teamB: 'Kent State',
    teamBLogo: 'upload/images/team/_MlyfrlwP0n50yFezrUXy',
  },
  {
    sessionId: 'MKd4gLrTtd',
    gameTime: '2025-02-08T03:00:00.000Z',
    title: 'Oregon State / UC Davis / Alaska',
    tags: ['FEMALE'],
    teamA: 'Oregon State',
    teamALogo: 'upload/images/team/SUgsb-OU3HGO7oWFRzfhd',
    teamB: 'UC Davis',
    teamBLogo: 'upload/images/team/FSkO6kJa0O9yd7mc4kyck',
    teamC: 'Alaska',
    teamCLogo: 'upload/images/team/TKmVq3b39HRy-6Fz1FJ8M',
  },
  {
    sessionId: 'DpBLT60AJR',
    gameTime: '2025-02-08T01:30:00.000Z',
    title: 'Southern Utah vs Sac State',
    tags: ['FEMALE'],
    teamA: 'Southern Utah',
    teamALogo: 'upload/images/team/O_CTTamlZYZlPw-le0CkD',
    teamB: 'Sac State',
    teamBLogo: 'upload/images/team/o6Lk9LwIRcHlaeJ_0z1ZJ',
  },
  {
    sessionId: 'ipyAJt4NHK',
    gameTime: '2025-02-08T22:00:00.000Z',
    title: 'Temple / Penn / Fisk',
    tags: ['FEMALE'],
    teamA: 'Temple',
    teamALogo: 'upload/images/team/-2aUC7ugrc_ZH7Gcip46v',
    teamC: 'Fisk',
    teamCLogo: 'upload/images/team/OfBVOo4OcfTz9HyW5LcJz',
    teamB: 'Penn',
    teamBLogo: 'upload/images/team/7H7GYktvhz4ygAfhHs-Ed',
  },
  {
    sessionId: 'TRIofah3iw',
    gameTime: '2025-02-09T21:00:00.000Z',
    title: 'UNC vs Clemson',
    tags: ['FEMALE'],
    teamA: 'UNC',
    teamALogo: 'upload/images/team/2zuCt_Fvldy7Y7Nh4yCuG',
    teamB: 'Clemson',
    teamBLogo: 'upload/images/team/M_ZZbKT6N5hoeI9gOzW08',
  },
  {
    sessionId: '2mms8egl3D',
    gameTime: '2025-02-09T18:00:00.000Z',
    title: 'UNH / Brown / Yale',
    tags: ['FEMALE'],
    teamA: 'UNH',
    teamALogo: 'upload/images/team/Ad1PKfzx7Ayt-g_rVjLaX',
    teamB: 'Brown',
    teamBLogo: 'upload/images/team/bm282RMpGww0qoStmL9Gr',
    teamC: 'Yale',
    teamCLogo: 'upload/images/team/-l6HqzpxgzJauEm2zPaNJ',
  },
  {
    sessionId: '0Do_8lTr6y',
    gameTime: '2025-02-08T02:00:00.000Z',
    title: 'Utah vs Arizona',
    tags: ['FEMALE'],
    teamA: 'Utah',
    teamALogo: 'upload/images/team/4lpZr33sN1C_mxiYQk0LN',
    teamB: 'Arizona',
    teamBLogo: 'upload/images/team/IvFg7MbvgCuET9yVr2j2-',
  },
  {
    sessionId: 'EONvv3QvLU',
    gameTime: '2025-02-06T23:00:00.000Z',
    title: 'UW-Eau Claire vs Simpson',
    tags: ['FEMALE'],
    teamA: 'UW-Eau Claire',
    teamALogo: 'upload/images/team/LauSa_ZJaGoO2xcVMSdzx',
    teamB: 'Simpson',
    teamBLogo: 'upload/images/team/e4ReYbsesszxOxrprmRfI',
  },
  {
    sessionId: 'RtCz7FIF7Y',
    gameTime: '2025-02-08T00:30:00.000Z',
    title: 'UWL vs UW-Stout',
    tags: ['FEMALE'],
    teamA: 'UWL',
    teamALogo: 'upload/images/team/3m9gkYeibmj5CT99Ay7Ey',
    teamB: 'UW-Stout',
    teamBLogo: 'upload/images/team/2uKGYOQeEjD5U6ofcavh2',
  },
  {
    sessionId: 'NeGLtAi4JH',
    gameTime: '2025-02-08T02:30:00.000Z',
    title: 'Washington vs UCLA',
    tags: ['FEMALE'],
    teamA: 'Washington',
    teamALogo: 'upload/images/team/PxHRrEmCh4h8dFmITMnSM',
    teamB: 'UCLA',
    teamBLogo: 'upload/images/team/LvA1VLX0gEgeqNb11WmGm',
  },
  {
    sessionId: 'PGxoDBxIun',
    gameTime: '2025-02-08T18:00:00.000Z',
    title: 'West Chester vs Ursinus',
    tags: ['FEMALE', 'STREAMING'],
    teamA: 'West Chester',
    teamALogo: 'upload/images/team/GMA9gX5dEfSAXJ_3RuGJe',
    teamB: 'Ursinus',
    teamBLogo: 'upload/images/team/X0jwCzEEfHa25usxd9wpD',
  },
  {
    sessionId: 'w6XLmt8LOj',
    gameTime: '2025-02-08T00:00:00.000Z',
    title: 'Winona State vs Hamline',
    tags: ['FEMALE'],
    teamA: 'Winona State',
    teamALogo: 'upload/images/team/WeJiXDNMqwN034-bzJsev',
    teamB: 'Hamline',
    teamBLogo: 'upload/images/team/lJNBrvLeQMzRkmVaGvf5e',
  },
  // {
  //   "sessionId": "0SkCxzvt5T",
  //   "gameTime": "2025-02-16T20:00:00.000Z",
  //   "title": "Wilberforce / Greenville / Fisk",
  //   "tags": [
  //     "FEMALE"
  //   ],
  //   "teamA": "Wilberforce",
  //   "teamALogo": "upload/images/team/zOJ1Nd2gMndq1UlNH-PT4",
  //   "teamB": "Greenville",
  //   "teamBLogo": "upload/images/team/DvpQ8aJqMsi2aXyHkqWpb",
  //   "teamC": "Fisk",
  //   "teamCLogo": "upload/images/team/OfBVOo4OcfTz9HyW5LcJz"
  // }
];

const week7 = [
  {
    sessionId: 'uKUDjUHqhZ',
    gameTime: '2025-02-15T02:00:00.000Z',
    title: 'Arizona State vs Utah',
    tags: ['FEMALE'],
    teamA: 'Arizona State',
    teamALogo: 'upload/images/team/ZaoNOG0LZOU9gwoMdGrnz',
    teamB: 'Utah',
    teamBLogo: 'upload/images/team/4lpZr33sN1C_mxiYQk0LN',
  },
  {
    sessionId: 'KbBjXhoRFp',
    gameTime: '2025-02-15T02:00:00.000Z',
    title: 'Arizona vs Iowa State',
    tags: ['FEMALE'],
    teamA: 'Arizona',
    teamALogo: 'upload/images/team/IvFg7MbvgCuET9yVr2j2-',
    teamB: 'Iowa State',
    teamBLogo: 'upload/images/team/9__Q1pXevKzpCxsjpRkoA',
  },
  {
    sessionId: 'xcYTt-aFFZ',
    gameTime: '2025-02-15T00:00:00.000Z',
    title: 'Georgia vs Arkansas',
    tags: ['FEMALE'],
    teamA: 'Georgia',
    teamALogo: 'upload/images/team/4meFHEcSQmI9SgiY7CIwr',
    teamB: 'Arkansas',
    teamBLogo: 'upload/images/team/Y4pMJrwhkkku_HEtQvYKN',
  },
  {
    sessionId: 'FYuZLbCnzv',
    gameTime: '2025-02-15T02:00:00.000Z',
    title: 'Boise State vs San Jose State',
    tags: ['FEMALE'],
    teamA: 'Boise State',
    teamALogo: 'upload/images/team/y_1ONBk8XQMNlCXqUolhg',
    teamB: 'San Jose State',
    teamBLogo: 'upload/images/team/2OpYQV8pvTrxham5gmSdg',
  },
  {
    sessionId: '7TWK1pWpLc',
    gameTime: '2025-02-16T19:00:00.000Z',
    title: 'Bowling Green vs WMU',
    tags: ['FEMALE'],
    teamA: 'Bowling Green',
    teamALogo: 'upload/images/team/2zMuDgazSTePXdNLXu6cG',
    teamB: 'WMU',
    teamBLogo: 'upload/images/team/4njSVBoc7CyvU415YzKj0',
  },
  {
    sessionId: 'u_2tNTHvYB',
    gameTime: '2025-02-14T23:00:00.000Z',
    title: 'Bridgeport vs LIU',
    tags: ['FEMALE', 'STREAMING'],
    teamA: 'Bridgeport',
    teamALogo: 'upload/images/team/4nMkHZZ0t_RtwvQEFqEMr',
    teamB: 'LIU',
    teamBLogo: 'upload/images/team/_aJBZM4RBuKXJMTdfBOvC',
  },
  {
    sessionId: 'XzTPiJLRBW',
    gameTime: '2025-02-15T02:00:00.000Z',
    title: 'BYU vs WVU',
    tags: ['FEMALE'],
    teamA: 'BYU',
    teamALogo: 'upload/images/team/IjOElnrwW4y1C_fOjQDqP',
    teamB: 'WVU',
    teamBLogo: 'upload/images/team/A7o7XcB3BbAxavXLRUBhF',
  },
  {
    sessionId: 'LR-RrtZDLD',
    gameTime: '2025-02-15T00:00:00.000Z',
    title: 'Clemson vs NC State',
    tags: ['FEMALE'],
    teamA: 'Clemson',
    teamALogo: 'upload/images/team/M_ZZbKT6N5hoeI9gOzW08',
    teamB: 'NC State',
    teamBLogo: 'upload/images/team/q3-Uqn-P1Eoa_XULt81_Z',
  },
  {
    sessionId: 'uBxUcSLQFC',
    gameTime: '2025-02-14T23:00:00.000Z',
    title: 'CMU vs Eastern Michigan',
    tags: ['FEMALE'],
    teamA: 'CMU',
    teamALogo: 'upload/images/team/TayK4o3QhC1gcKrHiPeX9',
    teamB: 'Eastern Michigan',
    teamBLogo: 'upload/images/team/6cWyxe7aJmnRMuhatRDwQ',
  },
  {
    sessionId: 'kkwkO3RD9W',
    gameTime: '2025-02-16T20:45:00.000Z',
    title: 'Denver / Oregon State / Ball State / TWU',
    tags: ['FEMALE'],
    teamA: 'Denver',
    teamALogo: 'upload/images/team/H04xwPRzTS5vbEFaXbx4W',
    teamB: 'Oregon State',
    teamBLogo: 'upload/images/team/SUgsb-OU3HGO7oWFRzfhd',
    teamC: 'Ball State',
    teamCLogo: 'upload/images/team/S7efA9rBwUCOUQONAATb6',
    teamD: 'TWU',
    teamDLogo: 'upload/images/team/RlQ4ghHJPe45JOh4yT6Uy',
  },
  {
    sessionId: '2JIL_rk_86',
    gameTime: '2025-02-15T01:15:00.000Z',
    title: 'Florida vs Auburn',
    tags: ['FEMALE'],
    teamA: 'Florida',
    teamALogo: 'upload/images/team/2EuQerjmGSQ3QnPZENaQl',
    teamB: 'Auburn',
    teamBLogo: 'upload/images/team/5WPFADXjkdDjfNTUnv9b2',
  },
  {
    sessionId: '5LulVjvAfB',
    gameTime: '2025-02-15T00:00:00.000Z',
    title: 'Gustavus vs Hamline',
    tags: ['FEMALE'],
    teamA: 'Gustavus',
    teamALogo: 'upload/images/team/jueyFEPEII5hbEk_pHILg',
    teamB: 'Hamline',
    teamBLogo: 'upload/images/team/lJNBrvLeQMzRkmVaGvf5e',
  },
  {
    sessionId: 'XhmilO-lgd',
    gameTime: '2025-02-15T01:00:00.000Z',
    title: 'Illinois State vs Greenville',
    tags: ['FEMALE'],
    teamA: 'Illinois State',
    teamALogo: 'upload/images/team/aw4N42hwRE2Ewc7dZ6prh',
    teamB: 'Greenville',
    teamBLogo: 'upload/images/team/DvpQ8aJqMsi2aXyHkqWpb',
  },
  {
    sessionId: 'ew6LHW_nAb',
    gameTime: '2025-02-16T19:00:00.000Z',
    title: 'Ithaca vs Brockport',
    tags: ['FEMALE'],
    teamA: 'Ithaca',
    teamALogo: 'upload/images/team/QA39D-hmXma8IzFp4m__b',
    teamB: 'Brockport',
    teamBLogo: 'upload/images/team/ghW_zO5ZLUPbYGniXSAR-',
  },
  {
    sessionId: '7AGaHm3RJj',
    gameTime: '2025-02-12T23:30:00.000Z',
    title: 'Ithaca vs Cortland',
    tags: ['FEMALE'],
    teamA: 'Ithaca',
    teamALogo: 'upload/images/team/QA39D-hmXma8IzFp4m__b',
    teamB: 'Cortland',
    teamBLogo: 'upload/images/team/aQuR5cQdzlNHGsY89kU1F',
  },
  {
    sessionId: '80kUHnhqHA',
    gameTime: '2025-02-15T00:00:00.000Z',
    title: 'Kentucky vs Temple',
    tags: ['FEMALE'],
    teamA: 'Kentucky',
    teamALogo: 'upload/images/team/jBxu8sY8t1WJSF6e43fOh',
    teamB: 'Temple',
    teamBLogo: 'upload/images/team/-2aUC7ugrc_ZH7Gcip46v',
  },
  {
    sessionId: 'bVYuWbcymg',
    gameTime: '2025-02-15T02:00:00.000Z',
    title: 'LSU vs Oklahoma',
    tags: ['FEMALE'],
    teamA: 'LSU',
    teamALogo: 'upload/images/team/k3g_c_3ZBsgwZ1KIup38W',
    teamB: 'Oklahoma',
    teamBLogo: 'upload/images/team/JjSGKxJh81xZlOMu-Oxd1',
  },
  {
    sessionId: 'JYDxWK-R2R',
    gameTime: '2025-02-15T00:30:00.000Z',
    title: 'Mizzou / Alabama / Illinois / Iowa State',
    tags: ['FEMALE'],
    teamA: 'Mizzou',
    teamALogo: 'upload/images/team/hSA2_OvgGcAwhVnnZDHjL',
    teamB: 'Alabama',
    teamBLogo: 'upload/images/team/5krk0_YwXvq_C58VbmYD6',
    teamC: 'Illinois',
    teamCLogo: 'upload/images/team/9f9Eo7XB1tk4pQ0zNIudV',
    teamD: 'Iowa State',
    teamDLogo: 'upload/images/team/9__Q1pXevKzpCxsjpRkoA',
  },
  {
    sessionId: 'ArajHUKw1u',
    gameTime: '2025-02-11T02:00:00.000Z',
    title: 'Oregon State / Washington / Alaska',
    tags: ['FEMALE'],
    teamA: 'Oregon State',
    teamALogo: 'upload/images/team/SUgsb-OU3HGO7oWFRzfhd',
    teamB: 'Washington',
    teamBLogo: 'upload/images/team/PxHRrEmCh4h8dFmITMnSM',
    teamC: 'Alaska',
    teamCLogo: 'upload/images/team/TKmVq3b39HRy-6Fz1FJ8M',
  },
  {
    sessionId: 'LCNCrrvbNU',
    gameTime: '2025-02-15T00:00:00.000Z',
    title: 'Pitt vs UNC',
    tags: ['FEMALE'],
    teamA: 'Pitt',
    teamALogo: 'upload/images/team/3dd_oTAMydg9FcSTb_14p',
    teamB: 'UNC',
    teamBLogo: 'upload/images/team/2zuCt_Fvldy7Y7Nh4yCuG',
  },
  {
    sessionId: 'sE-ewGkjJW',
    gameTime: '2025-02-15T17:00:00.000Z',
    title: 'RIC / Springfield / Cortland',
    tags: ['FEMALE'],
    teamA: 'RIC',
    teamALogo: 'upload/images/team/UVeeOAqNplREWtucLClb-',
    teamC: 'Cortland',
    teamCLogo: 'upload/images/team/aQuR5cQdzlNHGsY89kU1F',
    teamB: 'Springfield',
    teamBLogo: 'upload/images/team/B90OmKJn62sRjh404Y3eD',
  },
  {
    sessionId: 'BS6-kkpEGW',
    gameTime: '2025-02-15T22:00:00.000Z',
    title: 'Rutgers / Michigan / Kent State',
    tags: ['FEMALE'],
    teamA: 'Rutgers',
    teamALogo: 'upload/images/team/Keb4zpuxg0jy2H45dR0dD',
    teamB: 'Michigan',
    teamBLogo: 'upload/images/team/_pStX-aYWTvumVvf_BPYe',
    teamC: 'Kent State',
    teamCLogo: 'upload/images/team/_MlyfrlwP0n50yFezrUXy',
  },
  {
    sessionId: 'Ynx8Tu6XNc',
    gameTime: '2025-02-15T18:00:00.000Z',
    title: 'SCSU vs Penn',
    tags: ['FEMALE'],
    teamA: 'SCSU',
    teamALogo: 'upload/images/team/fdZErpFakdfjKY8u7q-kS',
    teamB: 'Penn',
    teamBLogo: 'upload/images/team/7H7GYktvhz4ygAfhHs-Ed',
  },
  // {
  //   sessionId: 'AKeGsZi0l5',
  //   gameTime: '2025-02-15T00:30:00.000Z',
  //   title: 'SEMO / Centenary / NIU',
  //   tags: ['FEMALE'],
  //   teamA: 'SEMO',
  //   teamALogo: 'upload/images/team/P9x9kIRocdJPCwq2sHg53',
  //   teamB: 'Centenary',
  //   teamBLogo: 'upload/images/team/qf-eFY2ramiGGd-35gIe3',
  //   teamC: 'NIU',
  //   teamCLogo: 'upload/images/team/F0jgfsNlRXL8b3Qq54Cb-',
  // },
  {
    sessionId: 'KD3K88bDLb',
    gameTime: '2025-02-15T01:30:00.000Z',
    title: 'Southern Utah vs Stanford',
    tags: ['FEMALE'],
    teamA: 'Southern Utah',
    teamALogo: 'upload/images/team/O_CTTamlZYZlPw-le0CkD',
    teamB: 'Stanford',
    teamBLogo: 'upload/images/team/0W6sAzrt4E1huvv82J16X',
  },
  {
    sessionId: 'xiM4EJJ5Ax',
    gameTime: '2025-02-11T01:30:00.000Z',
    title: 'Southern Utah vs Utah State',
    tags: ['FEMALE'],
    teamA: 'Southern Utah',
    teamALogo: 'upload/images/team/O_CTTamlZYZlPw-le0CkD',
    teamB: 'Utah State',
    teamBLogo: 'upload/images/team/HdErQDTTzua7xSgglBuEV',
  },
  {
    sessionId: '-TC-ykUHDj',
    gameTime: '2025-02-15T04:00:00.000Z',
    title: 'UCLA vs Penn State',
    tags: ['FEMALE'],
    teamA: 'UCLA',
    teamALogo: 'upload/images/team/LvA1VLX0gEgeqNb11WmGm',
    teamB: 'Penn State',
    teamBLogo: 'upload/images/team/RfZGXFyiH2JuGXjQ007hQ',
  },
  {
    sessionId: 'xwCZ65snZy',
    gameTime: '2025-02-15T00:00:00.000Z',
    title: 'UW-Eau Claire vs Winona State',
    tags: ['FEMALE'],
    teamA: 'UW-Eau Claire',
    teamALogo: 'upload/images/team/LauSa_ZJaGoO2xcVMSdzx',
    teamB: 'Winona State',
    teamBLogo: 'upload/images/team/WeJiXDNMqwN034-bzJsev',
  },
  {
    sessionId: '4TOhHhXCVc',
    gameTime: '2025-02-15T00:00:00.000Z',
    title: 'UW-Stout vs Simpson',
    tags: ['FEMALE'],
    teamA: 'UW-Stout',
    teamALogo: 'upload/images/team/2uKGYOQeEjD5U6ofcavh2',
    teamB: 'Simpson',
    teamBLogo: 'upload/images/team/e4ReYbsesszxOxrprmRfI',
  },
  {
    sessionId: 'XCPHYgIJju',
    gameTime: '2025-02-15T22:00:00.000Z',
    title: 'UW-Whitewater vs UW-Oshkosh',
    tags: ['FEMALE'],
    teamA: 'UW-Whitewater',
    teamALogo: 'upload/images/team/CbWKimoC_0RpBy-M-lcSy',
    teamB: 'UW-Oshkosh',
    teamBLogo: 'upload/images/team/Gq5i5lMGRFcxbXrFBVTp9',
  },
  {
    sessionId: '5m2IURHI3H',
    gameTime: '2025-02-15T02:30:00.000Z',
    title: 'Washington vs Maryland',
    tags: ['FEMALE'],
    teamA: 'Washington',
    teamALogo: 'upload/images/team/PxHRrEmCh4h8dFmITMnSM',
    teamB: 'Maryland',
    teamBLogo: 'upload/images/team/b3SmbX-UKRPk1HkzRPrD_',
  },
  {
    sessionId: 'aI2tgIYD5Z',
    gameTime: '2025-02-16T21:00:00.000Z',
    title: 'William & Mary vs West Chester',
    tags: ['FEMALE', 'STREAMING'],
    teamA: 'William & Mary',
    teamALogo: 'upload/images/team/I7JzeUKc_Vorq8GCRtXXT',
    teamB: 'West Chester',
    teamBLogo: 'upload/images/team/GMA9gX5dEfSAXJ_3RuGJe',
  },
  {
    sessionId: 'UpBJ7dXPql',
    gameTime: '2025-02-16T18:00:00.000Z',
    title: 'Yale / UNH / Brown / Towson',
    tags: ['FEMALE'],
    teamA: 'Yale',
    teamALogo: 'upload/images/team/-l6HqzpxgzJauEm2zPaNJ',
    teamB: 'UNH',
    teamBLogo: 'upload/images/team/Ad1PKfzx7Ayt-g_rVjLaX',
    teamD: 'Towson',
    teamDLogo: 'upload/images/team/7E-jHUkEK-WMl5uA31it-',
    teamC: 'Brown',
    teamCLogo: 'upload/images/team/bm282RMpGww0qoStmL9Gr',
  },
];

export const matches2025 = [
  week7,
  week6,
  week5,
  week4,
  [
    matchAF,
    matchAG,
    matchAH,
    matchAI,
    matchAJ,
    matchAK,
    matchAL,
    matchAM,
    matchAN,
    matchAO,
    matchAP,
    matchAQ,
    matchAR,
    matchAS,
    matchAT,
    matchAU,
    matchAV,
  ],
  [
    matchAW,
    matchAX,
    matchAY,
    matchAZ,
    matchAAA,
    matchAAB,
    matchAAC,
    matchAAD,
    // matchAAE,
  ],
  [matchAAF, matchAAG, matchAAH, matchAAI],
  [matchW, matchX, matchY, matchZ, matchAA, matchAB, matchAC, matchAD, matchAE],
  [],
  [],
  [matchSprouts1, matchSprouts2, matchSprouts3],
  [matchR, matchT, matchS, matchV, matchU],
  [matchJ, matchK, matchL, matchM, matchN, matchO, matchP],
  [],
  [matchI],
  [matchD, matchE, matchF, matchG, matchH],
  [matchPCC1, matchPCC2],
  [matchA, matchB, matchC],
];

export function useMatch(key) {
  const { data } = useQuery(GetMatch, {
    variables: {
      sessionKey: key,
    },
  });

  return data;
}
