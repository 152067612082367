import { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useQuery } from '@apollo/client';
import { displayName, abbvToApparatusName } from '../../utilities/conversions';
import GetMatchById from '../../apollo/queries/GetMatchById.graphql';
import * as Icons from '../helpers/icons';
import MatchUp from './matchup';
import './matchcountdown.css';

function CountDownTimer({ date }) {
  const getCountdown = () => {
    const timeRemaining = new Date(date) - new Date();
    const countdown = {
      Days: Math.floor(Math.abs(timeRemaining) / (1000 * 60 * 60 * 24)),
      Hours: Math.floor((Math.abs(timeRemaining) / (1000 * 60 * 60)) % 24),
      Minutes: Math.floor((Math.abs(timeRemaining) / 1000 / 60) % 60),
      Seconds: Math.floor((Math.abs(timeRemaining) / 1000) % 60),
    };
    return countdown;
  };
  const [countdown, setCountdown] = useState(getCountdown());

  useEffect(() => {
    const timer = setTimeout(() => {
      setCountdown(getCountdown());
    }, 1000);
    return () => clearTimeout(timer);
  });

  return (
    <div className="countdownTimer">
      <ul>
        <li>{countdown.Days}d•</li>
        <li>
          {countdown.Hours.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })}
          h•
        </li>
        <li>
          {countdown.Minutes.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })}
          m•
        </li>
        <li>
          {countdown.Seconds.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })}
          s
        </li>
      </ul>
    </div>
  );
}

function MatchCountdown({ sessionId, apparatus }) {
  const { data } = useQuery(GetMatchById, {
    variables: {
      id: sessionId,
    },
  });

  if (!data) {
    return null;
  }

  const { getSession } = data;
  const { startAt, sessionTeams, name, gender } = getSession;
  const sortedSessionTeams = sessionTeams.items
    .filter((st) => !st._deleted)
    .sort((a, b) => {
      return a.order - b.order;
    });

  const teams = sortedSessionTeams;
  const teamA = teams.length > 0 && teams[0].team;
  const teamB = teams.length > 1 && teams[1].team;
  const teamC = teams.length > 2 && teams[2].team;
  const teamD = teams.length > 3 && teams[3].team;
  const teamE = teams.length > 4 && teams[4].team;
  const teamF = teams.length > 5 && teams[5].team;
  const teamG = teams.length > 6 && teams[6].team;
  const teamH = teams.length > 7 && teams[7].team;

  //const customTheme = name.includes('Super 16') ? 'meanCountdown' : null;
  const isArkansas =
    name.includes('Arkansas') &&
    name.split(' ')?.[0] === 'Arkansas' &&
    teamA.id === 'lYQFBogMKiSAA21nVSNAF' &&
    gender === 'FEMALE'
      ? 'arkansas'
      : null;
  const isMichigan =
    name.includes('Michigan') &&
    name.split(' ')?.[0] === 'Michigan' &&
    teamA.id === 'YR7-a5UlVbf3aKaTX16o2' &&
    gender === 'FEMALE'
      ? 'michiganW'
      : null;
  const isUtah =
    name.includes('Utah') &&
    name.split(' ')?.[0] === 'Utah' &&
    teamA.id === 'j-8H25znXE8IcMuotCPb7' &&
    gender === 'FEMALE'
      ? 'utah'
      : null;
  const isBigTen =
    name.includes('Big Ten Championships') && gender === 'FEMALE'
      ? 'bigten'
      : null;
  const isUSAGCC =
    name.includes('USAG Collegiate Championships') && gender === 'MALE'
      ? 'USAGCC'
      : null;
  const isGEC =
    name.includes('GEC Championships') && gender === 'FEMALE' ? 'GEC' : null;
  const isMAC =
    name.includes('MAC Championships') && gender === 'FEMALE' ? 'MAC' : null;
  const isACC =
    name.includes('ACC Gymnastics Championship') && gender === 'FEMALE'
      ? 'ACC'
      : null;
  const isArkRegional =
    name.includes("NCAA Regional '24 @ Arkansas") && gender === 'FEMALE'
      ? 'ArkRegional'
      : null;
  const isCalRegional =
    name.includes("NCAA Regional '24 @ Cal") && gender === 'FEMALE'
      ? 'CalRegional'
      : null;

  const isECAC =
    name.includes('ECAC Championship') && gender === 'MALE' ? 'ECAC' : null;

  const isUSAGCN =
    name.includes('USAGCN') && gender === 'FEMALE' ? 'USAGCN' : null;

  const isSprouts =
    name.includes('Sprouts') && gender === 'FEMALE' ? 'sprouts' : null;

  const isWasatch =
    name.includes('Wasatch Classic') && gender === 'FEMALE' ? 'wasatch' : null;

  const isBestOfUtah =
    name.includes('Best of Utah') && gender === 'FEMALE' ? 'bestofutah' : null;

  const customTheme =
    isArkansas ||
    isMichigan ||
    isBigTen ||
    isGEC ||
    isUSAGCC ||
    isMAC ||
    isACC ||
    isArkRegional ||
    isCalRegional ||
    isECAC ||
    isUSAGCN ||
    isSprouts ||
    isWasatch ||
    isBestOfUtah ||
    isUtah ||
    null;

  const match = {
    sessionId,
    teamA: displayName(teamA.name, teamA.altNames),
    teamALogo: JSON.parse(sortedSessionTeams[0].team.logos)?.metaData?.filename,
    teamB: teamB && displayName(teamB.name, teamB.altNames),
    teamBLogo:
      teamB && JSON.parse(sortedSessionTeams[1].team.logos)?.metaData?.filename,
    teamC: teamC && displayName(teamC.name, teamC.altNames),
    teamCLogo:
      teamC && JSON.parse(sortedSessionTeams[2].team.logos)?.metaData?.filename,
    teamD: teamD && displayName(teamD.name, teamD.altNames),
    teamDLogo:
      teamD && JSON.parse(sortedSessionTeams[3].team.logos)?.metaData?.filename,
    teamE: teamE && displayName(teamE.name, teamE.altNames),
    teamELogo:
      teamE && JSON.parse(sortedSessionTeams[4].team.logos)?.metaData?.filename,
    teamF: teamF && displayName(teamF.name, teamF.altNames),
    teamFLogo:
      teamF && JSON.parse(sortedSessionTeams[5].team.logos)?.metaData?.filename,
    teamG: teamG && displayName(teamG.name, teamG.altNames),
    teamGLogo:
      teamG && JSON.parse(sortedSessionTeams[6].team.logos)?.metaData?.filename,
    teamH: teamH && displayName(teamH.name, teamH.altNames),
    teamHLogo:
      teamH && JSON.parse(sortedSessionTeams[7].team.logos)?.metaData?.filename,
    gameTime: startAt,
  };

  const apparatusLabel = apparatus
    ? abbvToApparatusName[apparatus.toUpperCase()].toUpperCase()
    : null;

  return (
    <div className={['matchCountdown', customTheme].join(' ')}>
      <div className="zoom vCenter">
        <Row className="vCenter">
          <span className="matchName">{name}</span>
        </Row>
        <Row className="vCenter">
          <MatchUp match={match} disabled={true} />
        </Row>
        <Row className="vCenter">
          <CountDownTimer date={startAt} />
        </Row>
      </div>
      <div className="byVirtius">
        {apparatus ? (
          <Row>
            <Col>{Icons?.[`${apparatus.toUpperCase()}noText`]}</Col>
            <Col className="vCenter" style={{ fontSize: '4rem' }}>
              {apparatus ? apparatusLabel : null}
            </Col>
          </Row>
        ) : (
          'by Virtius'
        )}
      </div>
    </div>
  );
}

export default MatchCountdown;
